import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {B2BUnitOption} from '../../../model/user.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {catchError, debounceTime, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {OCC_USER_ID_ANONYMOUS, SiteConnector, SiteContextActions, UserIdService} from '@spartacus/core';
import {SsabCustomerSelectConfirmComponent} from '../ssab-customer-select-confirm/ssab-customer-select-confirm.component';
import {NgSelectComponent} from '@ng-select/ng-select';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {createAction, Store} from '@ngrx/store';
import {CreditCheckPerformAction, ReloadCurrentUserRequest} from '../../../service/user/ssab-user.action';
import {SsabContactSalesProductListService} from '../../../service/contactsales/ssab-contactsales.service';
import {OrderEntry} from '@spartacus/cart/base/root';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabCustomerSelectConfirmData} from '../ssab-customer-select-confirm/ssab-customer-select-confirm-layout.config';
import {SsabWishListFacade} from '../../../service/cart/ssab-wish-list.facade';
import {AsmService} from "@spartacus/asm/core";
import {CartActions} from "@spartacus/cart/base/core";

@Component({
  selector: 'ssab-cx-customer-select',
  templateUrl: './ssab-customer-select.component.html',
})

export class SsabCustomerSelectComponent implements OnInit, OnDestroy {

  selectedUnit$: Observable<B2BUnitOption>;
  enabled$: Observable<boolean>;
  previouslySelectedUnit: B2BUnitOption;
  subscriptions: Subscription = new Subscription();

  protected modal: SsabCustomerSelectConfirmComponent;

  @ViewChild('units')
  protected selectBox: NgSelectComponent;

  units$: Observable<B2BUnitOption[]>;
  unitInput$ = new BehaviorSubject<string>('*');

  constructor(
    protected userService: SsabUserService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected gtmService: SsabGtmService,
    protected activeCartService: SsabActiveCartService,
    protected cdr: ChangeDetectorRef,
    protected siteConnector: SiteConnector,
    private userIdService: UserIdService,
    protected contactSalesProductListService: SsabContactSalesProductListService,
    protected wishListService: SsabWishListFacade,
    protected store: Store,
    protected launchDialogService: LaunchDialogService,
    protected asmService: AsmService
  ) {
  }

  ngOnInit(): void {
    this.selectedUnit$ = this.userService.getCurrentUnit();
    this.enabled$ = this.userService.isShowCustomerSelection();
    this.loadUnits();
  }

  reLoadAllUnits() {
    this.unitInput$.next('*');
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  loadUnits(): void {
    this.units$ = this.unitInput$
      .pipe(
        debounceTime(300),
        switchMap(term => {
          return this.userService.searchUnits(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      );
  }

  public selectUnit(selectedUnit: B2BUnitOption): void {
    if (selectedUnit.uid === this.previouslySelectedUnit?.uid) {
      return;
    }
    this.hasActiveNotEmptyCart().subscribe(hasActiveCart => {
      if (hasActiveCart) {
        this.selectedUnit$
          .pipe(
            take(1),
            tap(unit => this.previouslySelectedUnit = unit)
          ).subscribe();
        // Show confirmation dialog before changing the customer
        this.openConfirmationDialog(selectedUnit);
      } else {
        // No cart or empty cart => just change customer
        this.changeCustomer(selectedUnit);
      }
    }).unsubscribe();
  }

  protected changeCustomer(selectedUnit: B2BUnitOption): void {
    if (selectedUnit.uid) {
      this.selectedUnit$ = this.userService.selectUnit(selectedUnit.uid);
      this.subscriptions.add(
        this.selectedUnit$
          .subscribe((unit) => {
            this.asmService.customerSearchReset();
            this.store.dispatch(new ReloadCurrentUserRequest());
            this.userService.get();
            this.store.dispatch(new CartActions.ClearCartState());
            this.activeCartService.reLoadCart(true);
            this.reloadContactSalesProductList();
            this.wishListService.reloadWishlist();
            this.pushGTMUserSwitch(unit);
            this.reloadCurrencies();
            this.store.dispatch(new CreditCheckPerformAction({stickyNotification: true, clearOldNotifications: true}));
            this.reLoadAllUnits();
          })
      );
    }
  }

  reloadContactSalesProductList(): void {
    this.userIdService.getUserId().pipe(
      take(1),
      filter(userId => userId !== OCC_USER_ID_ANONYMOUS)
    ).subscribe((userId) => {
      this.contactSalesProductListService.loadContactSalesProductList(userId);
    });
  }

  reloadCurrencies(): void {
    const reloadCurrencies = createAction(SiteContextActions.LOAD_CURRENCIES_SUCCESS, (payload: any) => ({'payload': payload}));
    this.subscriptions.add(
      this.siteConnector.getCurrencies().subscribe(curr => {
        var ev = reloadCurrencies(curr);
        this.store.dispatch(ev);
      })
    );
  }

  protected openConfirmationDialog(selectedUnit: B2BUnitOption): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CUSTOMER_SELECT_CONFIRM, undefined, undefined);

    // Handle confirm -> change customer
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dataDialog: SsabCustomerSelectConfirmData) => {
        if (dataDialog) {
          if (dataDialog?.afterConfirm) {
            this.previouslySelectedUnit = null;
            this.changeCustomer(selectedUnit);
          }
        }
        if (dataDialog?.afterDismiss && this.previouslySelectedUnit !== null) {
          this.subscriptions.add(
            this.selectedUnit$.subscribe((unit: B2BUnitOption) => {
              this.selectedUnit$ = of(this.previouslySelectedUnit);
              const previouslySelectedItem = this.selectBox.itemsList.findItem(this.previouslySelectedUnit?.uid);
              if (previouslySelectedItem) {
                this.selectBox.select(previouslySelectedItem);
              }
              this.previouslySelectedUnit = null;
              this.cdr.detectChanges();
              this.refresh();
            })
          );
        }
        if (dataDialog?.afterDismiss || dataDialog?.afterConfirm) {
          this.launchDialogService.clear(LAUNCH_CALLER.CUSTOMER_SELECT_CONFIRM);
          this.launchDialogService.closeDialog('closed');
        }
      })
    );
  }

  protected hasActiveNotEmptyCart(): Observable<boolean> {
    return this.activeCartService.getEntries().pipe(
      filter(Boolean),
      map((entries: OrderEntry[]) => entries.length > 0)
    );
  }

  protected refresh(): void {
    this.router.navigated = false;
    this.router.navigate(
      ['./'],
      {
        relativeTo: this.route
      });
  }

  protected pushGTMUserSwitch(unit: B2BUnitOption): void {
    this.userService.get()
      .pipe(filter((user) => user?.uid !== undefined))
      .pipe(take(1))
      .subscribe(() => {
        this.gtmService.pushUserSwitch();
      });
  }

  customSearchFn(term: string, item: any): boolean {
    term = term.toLowerCase();
    return (item.name != null ? item.name.toLowerCase().includes(term) : false) ||
      (item.city != null ? item.city.toLowerCase().includes(term) : false);
  }
}
