import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ProductLoadingService, ProductScope, ProductService, StateWithProduct} from '@spartacus/core';
import {SsabCoilComparisonRequest, SsabCoilComparisonResponse, SsabCoilSearchRequest, SsabCoilSearchResult, SsabProduct, SsabStock, SsabUnitOfMeasure} from "../../model/product.model";
import {SsabProductConnector} from "./ssab-product.connector";
import {SsabDocolSearchRequest, SsabDocolSearchResponse} from '../../model/docol.model';
import {SsabProductDocolConnector} from './ssab-product-docol.connector';


@Injectable({
  providedIn: 'root',
})
export class SsabProductService extends ProductService {

  constructor(
    protected store: Store<StateWithProduct>,
    productLoading: ProductLoadingService,
    protected productConnector: SsabProductConnector,
    protected docolConnector: SsabProductDocolConnector
  ) {
    super(store, productLoading);
  }

  get(productCode: string, scopes?: (ProductScope | string)[] | ProductScope | string): Observable<SsabProduct> {
    if( scopes){
      return super.get(productCode, scopes) as Observable<SsabProduct>; // page name resolver result can be cached
    }
    return this.productConnector.get(productCode) as Observable<SsabProduct>; //product details page cannot
  }

  getProductWithScope(productCode: string, scopes?: string): Observable<SsabProduct> {
    return this.productConnector.get(productCode, scopes) as Observable<SsabProduct>; //product details page cannot
  }

  getCatalogPrice(product: SsabProduct, unit: SsabUnitOfMeasure, quantity: number): Observable<SsabProduct> {
    return this.productConnector.getCatalogPrice(product, unit, quantity);
  }

  getCatalogAvailability(product: SsabProduct, unit: SsabUnitOfMeasure): Observable<SsabStock> {
    return this.productConnector.getCatalogAvailability(product, unit);
  }

  getDocolList(params: SsabDocolSearchRequest): Observable<SsabDocolSearchResponse>{
    return this.docolConnector.getDocolList('current', params);
  }

  searchCoils(request: SsabCoilSearchRequest): Observable<SsabCoilSearchResult> {
    return this.productConnector.searchCoils(request);
  }

  compareCoils(request: SsabCoilComparisonRequest): Observable<SsabCoilComparisonResponse> {
    return this.productConnector.compareCoils(request);
  }
}
