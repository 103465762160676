import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OrderTrackingTypes, SsabOrderHistory} from '../../../../../model/order.model';
import {UntypedFormGroup} from '@angular/forms';
import {hideSearchOptions, toggleSearchOption} from '../../../../shared/utils/functions/ssab-functions-utils';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SsabNgbDateParserFormatter} from '../../../../shared/datepicker/ssab-datepicker-parser-formatter';
import {CalendarNavigationTypes} from '../../../../../model/misc.model';
import {DatePipe} from '@angular/common';
import {WindowRef} from '@spartacus/core';
import {SsabInputRangeComponent} from '../../../../../shared/input-range/ssab-input-range.component';
import {BehaviorSubject, concat, merge, Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {SsabUserService} from "../../../../../service/user/ssab-user.service";
import {SsabUser} from "../../../../../model/user.model";

@Component({
  selector: 'ssab-cx-order-history-form',
  templateUrl: './ssab-order-history-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderHistoryFormComponent implements OnInit, OnDestroy {

  @Input() orders: SsabOrderHistory;
  @Input() searchOrderHistoryForm: UntypedFormGroup;
  @Input() selectedView: OrderTrackingTypes;
  @Output() resetForm = new EventEmitter<void>();

  companies$: Observable<any>;
  customerIds$: Observable<any>;
  minTermLength: number = 3;

  customerIdsInput$ = new BehaviorSubject<string>(null);
  companiesInput$ = new BehaviorSubject<string>(null);
  shipToLocationsInput$ = new BehaviorSubject<string>(null);

  selectedCompanyId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  selectedCustomerId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  shipToLocations$: Observable<any>;

  user$: Observable<SsabUser>;
  @ViewChild('orderSearch') orderSearch: ElementRef<HTMLElement>;

  @ViewChild('customers') customersElement: NgSelectComponent;
  @ViewChild('shipToLocation') shipToLocationElement: NgSelectComponent;

  subscriptions: Subscription = new Subscription();
  calendarNavigationTypes = CalendarNavigationTypes;
  lateOrderNotifications = [true, false];
  allowedSalesChannels: string[];
  showHideFilter = true;
  orderTypes = OrderTrackingTypes;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    protected aWinRef: WindowRef,
    protected userService: SsabUserService,
  ) {
    this.user$ = this.userService.getUserDetails();
  }

  ngOnInit(): void {
    this.allowedSalesChannels = this.orders?.allowedSalesChannels?.map(a => 'salesChannel_' + a)
      .concat(this.orders?.allowedPlants?.map(p => 'plant_' + p));

    this.subscriptions.add(
      this.router.events.subscribe((val) => {
        // always scroll to top on new navigation End
        if (val instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      })
    );

    this.companies$ = concat(
      of(this.orders?.allowedCompanies), // default items
      this.companiesInput$.pipe(
        filter(term =>
          term !== null
        ),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(term => {
          if (term?.length >= this.minTermLength) {
            return this.userService.searchUnitForCompanyFilter('orderhistory', term, '').pipe(
              catchError(() => of(this.orders?.allowedCompanies)),
            )
          } else {
            return of(this.orders?.allowedCompanies);
          }
        })
      )
    );

    this.customerIds$ = merge(
      of(this.orders?.allowedCustomers), // default items
      this.selectedCompanyId$.pipe(
        filter(id => id !== undefined),
        switchMap((customerId) => {
          this.customersElement.clearModel();
          if (this.isNullOrEmpty(this.getSelectedCompanyId())) {
            return of(this.orders?.allowedCustomers);
          } else {
            return this.userService.searchUnitForCustomerIdFilter('orderhistory', '', this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedCustomers)),
            )
          }
        })
      ),
      this.customerIdsInput$.pipe(
        filter(term =>
          term !== null
        ),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(term => {
          // this works like this: if term length more than 3, then perform backend search
          // otherwise if term was removed - show the customerIds list as it was returned
          // by service layer order history service (might contain units that are not in commerce)
          // e.g. if commerce sends buyerId=1 but SL finds buyer,payer,consignee 1,2,3 for this request
          // so 2 and 3 for example are not in commerce but should still be searchable
          if (term?.length >= this.minTermLength) {
            return this.userService.searchUnitForCustomerIdFilter('orderhistory', term, this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedCustomers)),
            )
          } else if (!this.isNullOrEmpty(this.getSelectedCompanyId())) {
            return this.userService.searchUnitForCustomerIdFilter('orderhistory', '', this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedCustomers)),
            )
          } else {
            return of(this.orders?.allowedCustomers);
          }
        })
      )
    );

    this.shipToLocations$ = merge(
      of(this.orders?.allowedShipToCustomers), // default items
      this.selectedCustomerId$.pipe(
        filter(id => id !== undefined),
        switchMap((customerId) => {
          this.shipToLocationElement.clearModel();
          //When searching for ship to location we need to have either customer or company present, otherwise default
          if (this.isNullOrEmpty(this.getSelectedCompanyId()) && this.isNullOrEmpty(customerId)) {
            return of(this.orders?.allowedShipToCustomers);
          } else {
            return this.userService.searchUnitForShipToFilter('orderhistory', '', customerId, this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedShipToCustomers)),
            )
          }
        })
      ),
      this.selectedCompanyId$.pipe(
        filter(id => id !== undefined),
        switchMap((companyId) => {
          this.shipToLocationElement.clearModel();
          //When searching for ship to location we need to have either customer or company present, otherwise default
          if (this.isNullOrEmpty(companyId)) {
            return of(this.orders?.allowedShipToCustomers);
          } else {
            return this.userService.searchUnitForShipToFilter('orderhistory', '', this.getSelectedCustomerId(), companyId).pipe(
              catchError(() => of(this.orders?.allowedShipToCustomers)),
            )
          }
        })
      ),
      this.shipToLocationsInput$.pipe(
        filter(term =>
          term !== null
        ),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(term => {
          if (term?.length >= this.minTermLength) {
            return this.userService.searchUnitForShipToFilter('orderhistory', term, this.getSelectedCustomerId(), this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedShipToCustomers)),
            )
          //With ship to locations, we want to make sure when clearing that we didn't have existing selected fields
          } else if (!this.isNullOrEmpty(this.getSelectedCustomerId()) || !this.isNullOrEmpty(this.getSelectedCompanyId())) {
            return this.userService.searchUnitForShipToFilter('orderhistory', '', this.getSelectedCustomerId(), this.getSelectedCompanyId()).pipe(
              catchError(() => of(this.orders?.allowedShipToCustomers)),
            )
          } else {
            return of(this.orders?.allowedShipToCustomers);
          }
        })
      )
    );
  }

  clearShipTo(): void {
    this.shipToLocationsInput$.next(undefined);
  }

  clearCustomers(): void {
    this.customerIdsInput$.next(undefined);
  }

  clearCompanies(): void {
    this.companiesInput$.next(undefined);
  }


  onCompanyChange(): void {
    //this.hideSearchOptions(divElement);
    this.selectedCompanyId$.next(this.getSelectedCompanyId());
  }

  protected getSelectedCompanyId(): string {
    return this.searchOrderHistoryForm.controls.company.value;
  }

  onCustomerChange(divElement: HTMLDivElement): void {
    //   this.hideSearchOptions(divElement);
    this.selectedCustomerId$.next(this.getSelectedCustomerId());
  }

  protected getSelectedCustomerId(): string {
    return this.searchOrderHistoryForm.controls.customers.value;
  }

  showHideFilterOptions(): void {
    const backgroundImage = document.querySelector('ssab-cx-background-image') as HTMLElement;
    backgroundImage.classList.toggle('cx-image-filters');
    if (backgroundImage.classList.contains('cx-image-filters')) {
      if (this.orderSearch?.nativeElement != null && this.orderSearch?.nativeElement.classList.contains("is-internal-user")) {
        (backgroundImage.querySelector('cx-media') as HTMLElement).style.height =
          (this.selectedView === this.orderTypes.Orders ? '600px' : '695px');
        (backgroundImage.querySelector('cx-media img') as HTMLElement).style.height =
          (this.selectedView === this.orderTypes.Orders ? '' : '695px'); // ssab-cx-background-image height is only 620px so need to override for order items.
      } else {
        (backgroundImage.querySelector('cx-media') as HTMLElement).style.height =
          (this.selectedView === this.orderTypes.Orders ? '600px' : '600px');
      }
    } else {
      (backgroundImage.querySelector('cx-media') as HTMLElement).style.height = '';
    }
    this.showHideFilter = !this.showHideFilter;
    this.cdr.detectChanges();
  }

  hideFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-order-history-search')
      .querySelector('.cx-order-history-form');
    innerSearch.classList.add('d-none');
  }

  isInvalid(fieldName: string): boolean {
    return this.searchOrderHistoryForm.get(fieldName) && this.searchOrderHistoryForm.get(fieldName).invalid;
  }

  hideSearchOptions(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    hideSearchOptions(divElement, inputRange);
  }

  toggleFilter(divElement: HTMLDivElement): void {
    toggleSearchOption(divElement);
  }

  search(): void {
    const queryParams: Params = this.setQueryParams();
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || (Array.isArray(queryParams[key]) && queryParams[key].length == 0)) {
        delete queryParams[key];
      }
    });
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams
      });
  }

  setQueryParams() {
    return {
      orderType: this.selectedView,
      lateOrderNotification: !this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.lateOrderNotification.value ? this.searchOrderHistoryForm.controls.lateOrderNotification.value : null,
      orderReference:
        this.searchOrderHistoryForm.controls.orderReference.value ? this.searchOrderHistoryForm.controls.orderReference.value : null,
      customers:
        this.searchOrderHistoryForm.controls.customers.value ? this.searchOrderHistoryForm.controls.customers.value : null,
      company:
        this.searchOrderHistoryForm.controls.company.value ? this.searchOrderHistoryForm.controls.company.value : null,
      shipToLocation:
        this.searchOrderHistoryForm.controls.shipToLocation.value ? this.searchOrderHistoryForm.controls.shipToLocation.value : null,
      status:
        this.searchOrderHistoryForm.controls.status.value ? this.searchOrderHistoryForm.controls.status.value : null,
      grade:
        this.searchOrderHistoryForm.controls.grade.value ? this.searchOrderHistoryForm.controls.grade.value : null,
      salesChannel:
        this.searchOrderHistoryForm.controls.salesChannel.value ? this.searchOrderHistoryForm.controls.salesChannel.value : null,
      creationDateStart:
        this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.creationDateStart.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.creationDateStart.value) : null,
      creationDateEnd:
        this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.creationDateEnd.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.creationDateEnd.value) : null,
      confirmedWeekDateStart:
        !this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.confirmedWeekDateStart.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.confirmedWeekDateStart.value) : null,
      confirmedWeekDateEnd:
        !this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.confirmedWeekDateEnd.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.confirmedWeekDateEnd.value) : null,
      estimatedWeekDateStart:
        !this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.estimatedWeekDateStart.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.estimatedWeekDateStart.value) : null,
      estimatedWeekDateEnd:
        !this.isOrderTrackingPage() && this.searchOrderHistoryForm.controls.estimatedWeekDateEnd.value ?
          this.getDateToString(this.searchOrderHistoryForm.controls.estimatedWeekDateEnd.value) : null,
      sliderControl:
        (this.searchOrderHistoryForm.controls.sliderControl.value &&
          (this.searchOrderHistoryForm.controls.sliderControl.value[0] !== this.orders.thicknessMin ||
            this.searchOrderHistoryForm.controls.sliderControl.value[1] !== this.orders.thicknessMax)) ? this.searchOrderHistoryForm.controls.sliderControl.value : null
    };
  }

  isOrderTrackingPage(): boolean {
    return OrderTrackingTypes.Orders === this.selectedView;
  }

  getDateToString(date: any): string {
    if (date) {
      if (date.year && date.month && date.day) {
        return this.datepipe.transform(new Date(date.year, date.month - 1, date.day), 'yyyy-MM-dd');
      }
      return this.datepipe.transform(date, 'yyyy-MM-dd');
    }
    return null;
  }

  afterEnter(orderReference: string): void {
    const queryParams: Params = {orderReference: orderReference ? orderReference : null};
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      }).then(() => {
      this.aWinRef.location.reload();
    });
  }

  resetSelect(ngSelectComponent: NgSelectComponent): void {
    ngSelectComponent.clearModel();
    ngSelectComponent.close();
  }

  resetCompanySelect(ngSelectComponent: NgSelectComponent): void {
    this.companiesInput$.next('');
    this.resetSelect(ngSelectComponent);
  }

  resetCustomersSelect(ngSelectComponent: NgSelectComponent): void {
    this.customerIdsInput$.next('');
    this.resetSelect(ngSelectComponent);
  }

  resetShipToLocationSelect(ngSelectComponent: NgSelectComponent): void {
    this.shipToLocationsInput$.next('');
    this.resetSelect(ngSelectComponent);
  }

  convertDate(d: any): NgbDateStruct {
    if (d) {
      if (d.year && d.month && d.day) {
        return d;
      }
      return this.ngbDateParserFormatter.parse(d);
    }
    return null;
  }

  reset(): void {
    this.resetForm.emit();
    this.search();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isNullOrEmpty(str: string | null | undefined): boolean {
    return !(str ?? '').trim();
  }
}

