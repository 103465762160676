import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthFlowRoutesService, AuthRedirectService, AuthRedirectStorageService, BaseSiteService, CmsService, CurrencyService, EventService, LanguageService, RoutingService, SiteContextActions, WindowRef,} from '@spartacus/core';
import {concatMap, filter, take} from 'rxjs/operators';
import {SsabUserService} from '../user/ssab-user.service';
import {of, zip} from 'rxjs';
import {SsabUser} from '../../model/user.model';
import {EloquaForms, GlobalLogin} from '../../model/misc.model';
import {Store} from "@ngrx/store";
import {ReloadCurrentUserRequest} from "../user/ssab-user.action";
import {AsmService} from "@spartacus/asm/core";

@Injectable({
  providedIn: 'root',
})
export class SsabAuthRedirectService extends AuthRedirectService {

  constructor(
    protected routing: RoutingService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authFlowRoutesService: AuthFlowRoutesService,
    protected userService: SsabUserService,
    protected baseSiteService: BaseSiteService,
    protected languageService: LanguageService,
    protected winRef: WindowRef,
    protected store: Store,
    protected cmsService: CmsService,
    protected asmService: AsmService,
    protected currencyService: CurrencyService
  ) {
    super(routing, router, authRedirectStorageService, authFlowRoutesService);
  }

  private ignoredUrls = new Set<string>();
  private lastAuthGuardNavigation: {
    url: string;
    navigationId: number;
  };

  protected init() {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects.indexOf('/login') < 0 && event.urlAfterRedirects.indexOf('/store-selector') < 0) {
        this.setRedirectUrl(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Redirect to global login if user has access to more than one store
   *  or to homepage if user has only one store, redirect url is not needed anymore
   *  as user will be always redirect to homepage after successful log in
   */
  redirect(sendEloquaData?: boolean): void {
    this.userService.get()
      .pipe(
        filter(Boolean),
        concatMap(user => zip(
          of(user),
          this.userService.getElOQUABaseUrl(EloquaForms.Login)
        )), take(1))
      .subscribe(([userDetails, eloquaUrl]) => {
        if (userDetails && eloquaUrl && sendEloquaData) {
          this.userService.sendELOQUAData(eloquaUrl).subscribe();
        }
        //We set a timer so that eloqua data can be send first and then
        // we proceed on the next steps for checking if user has more than one access
        setTimeout(() => {
          if (userDetails === undefined || !userDetails || (userDetails as SsabUser).storeAccesses.length > 1) {
            this.baseSiteService.setActive(GlobalLogin.GlobalBaseSite);
            this.router.navigateByUrl('/store-selector');
          }
          if (userDetails && (userDetails as SsabUser).storeAccesses.length === 1) {
            this.handleStoreSelection((userDetails as SsabUser).storeAccesses[0], true, false);
          }
          this.lastAuthGuardNavigation = undefined;
        }, 1000);
      });
  }

  handleStoreSelection(baseSiteId: string, redirect: boolean, refreshPage: boolean): void {
    this.baseSiteService.get(baseSiteId)
      .pipe(take(1))
      .subscribe(baseSite => {
        const language = baseSite.baseStore.defaultLanguage?.isocode ?? 'en';
        this.store.dispatch(new SiteContextActions.SetActiveLanguage('en'));// switch first to EN, to avoid issues with uncompatible languages
        this.getLocalStorage().setItem(GlobalLogin.BaseSiteActive, baseSite.uid);
        this.baseSiteService.setActive(baseSite.uid);
        this.store.dispatch(new ReloadCurrentUserRequest());
        this.asmService.customerSearchReset();
        if (redirect) {
          this.handleRedirect(refreshPage, language);
        }
      });
  }

  handleRedirect(refreshPage: boolean, language: string) {
    this.authRedirectStorageService.getRedirectUrl()
      .pipe(take(1))
      .subscribe(url => {
        this.store.dispatch(new SiteContextActions.SetActiveLanguage(language));

        let redirection = '/';
        const split = url?.split('/');
        if ((url?.indexOf('search') >= 0 || url?.indexOf('my-account') >= 0 || url?.indexOf('scanner') >= 0) && split?.length > 3) {
          redirection += split.splice(3).join('/');
        }
        this.router.navigateByUrl(redirection, {replaceUrl: true})
          .then(() => {
            this.cmsService.refreshLatestPage();
            this.cmsService.refreshComponent('NavigationUIComponent')
            this.cmsService.refreshComponent('CategoryNavigationComponent')
            if (refreshPage) {
              // this.winRef.location.reload();
            }
          });
      });
  }

  protected getLocalStorage(): Storage {
    return this.winRef.localStorage;
  }

  /**
   * Saves url of a page that user wanted to access, but wasn't yet logged in.
   */
  reportAuthGuard() {

    const {url, navigationId} = this.getCurrentNavigation();
    this.lastAuthGuardNavigation = {url, navigationId};
  }

  /**
   * Saves url of a page that was accessed before entering a page only for not auth users.
   */
  reportNotAuthGuard() {
    const {url, initialUrl, navigationId} = this.getCurrentNavigation();

    this.ignoredUrls.add(url);

    // Don't save redirect url if you've already come from page with NotAuthGuard (i.e. user has come from login to register)
    if (!this.ignoredUrls.has(initialUrl)) {
      // We compare the navigation id to find out if the url cancelled by AuthGuard (i.e. my-account) is more recent
      // than the last opened page
      if (
        !this.lastAuthGuardNavigation ||
        this.lastAuthGuardNavigation.navigationId < navigationId - 1
      ) {
        this.authRedirectStorageService.setRedirectUrl(initialUrl.split('homepage')[0]);

        this.lastAuthGuardNavigation = undefined;
      }
    }
  }

  private getCurrentNavigation(): {
    navigationId: number;
    url: string;
    initialUrl: string;
  } {
    const initialUrl = this.router.url;
    const navigation = this.router.getCurrentNavigation();
    const url = navigation ? this.router.serializeUrl(navigation.finalUrl) : '';
    return {
      navigationId: navigation.id,
      url,
      initialUrl,
    };
  }
}
