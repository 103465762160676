import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {SsabCartHeaderComponent} from './ssab-cart-header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {SsabInputModule} from '../../../shared/input/ssab-input.module';
import {SsabCartNewAddressComponent} from './address/ssab-cart-new-address.component';
import {SsabCartSharedModule} from '../cart-shared/ssab-cart-shared.module';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SpinnerModule} from '@spartacus/storefront';
import {SsabAddEmailModule} from '../../shared/add-emails/ssab-add-email.module';
import {cartNewAddressLayout} from './address/ssab-cart-new-address-layout.config';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        SsabInputModule,
        SsabCartSharedModule,
        NgbPopoverModule,
        SpinnerModule,
        SsabAddEmailModule
    ],
    providers: [
        provideDefaultConfig({
            cmsComponents: {
                CartTotalsComponent: {
                    component: SsabCartHeaderComponent,
                },
            },
        } as CmsConfig),
        provideDefaultConfig(cartNewAddressLayout)
    ],
    declarations: [SsabCartHeaderComponent, SsabCartNewAddressComponent],
    exports: [SsabCartHeaderComponent, SsabCartNewAddressComponent],
})
export class SsabCartHeaderModule {
}
