import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef} from "@angular/core";

import {NgbDate, NgbDatepicker, NgbDatepickerConfig, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

import {SsabNgbDatepickerI18n} from './ssab-datepicker-i18n';
import {SsabInputComponent} from '../../../shared/input/ssab-input.component';
import {SsabNgbDateParserFormatter} from './ssab-datepicker-parser-formatter';
import {FormUtils} from '../../../shared/util/form-utils';
import {CalendarNavigationTypes} from '../../../model/misc.model';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {DateUtils} from "../../../shared/util/date-utils";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'ssab-datepicker-input',
  templateUrl: './ssab-datepicker-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabDatepickerInputComponent extends SsabInputComponent implements OnDestroy {
  @Input()
  firstDayOfWeek = 2;
  @Input()
  selectedDate: NgbDateStruct;
  @Input()
  customDayTemplate: TemplateRef<any>;
  @Output()
  navigationArrowClickEvent = new EventEmitter<Date>();
  @Output()
  onChangeEvent = new EventEmitter<Date>();
  @Input() navigation: string = CalendarNavigationTypes.Arrows;

  @Input() allowedDates;
  @Input() showWeekNumbers = true;

  dateFormat: string;
  private subscriptions: Subscription = new Subscription();

  constructor(config: NgbDatepickerConfig,
              protected cdr: ChangeDetectorRef,
              protected elRef: ElementRef,
              protected ngbDatepickerI18n: NgbDatepickerI18n,
              protected activeCartService: SsabActiveCartService,
              protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
              private globalConfigurationService: SsabGlobalconfigurationService,) {
    super(elRef);
    config.navigation = "arrows";
    // days that don't belong to current month are not visible and the space should be collapsed
    config.showWeekNumbers = false;
    config.outsideDays = "collapsed";

    this.subscriptions.add(
      this.globalConfigurationService.getDateFormat()
        .subscribe(
          format => this.dateFormat = format, err => console.error(err)
        )
    );
  }

  hasValue(): boolean {
    if (this.parentForm) {
      const input: any = this.parentForm.get(this.nameForm);
      return input && input.value && (this.isNotEmptyString(input) || this.isNgbDateStruct(input));
    }
    return false;
  }

  isNotEmptyString(object: any): boolean {
    const value: any = object.value;
    return value && typeof value === 'string' && value !== '';
  }

  isNgbDateStruct(object: any): object is NgbDateStruct {
    const value: any = object.value;
    return 'year' in value && 'month' in value && 'day' in value;
  }

  getFormattedDate(): string {
    if (this.selectedDate) {
      return DateUtils.convertUIDate(this.dateFormat, this.ngbDateParserFormatter.toDate(this.selectedDate));
    }
    return null;
  }

  isNotValid(formControlName: string): boolean {
    return !this.parentForm || FormUtils.isNotValidField(
      this.parentForm,
      formControlName,
      this.submitClicked
    );
  }

  ngOnDestroy(): void {
    // Providers should be destroyed manually
    if (this.ngbDatepickerI18n instanceof SsabNgbDatepickerI18n) {
      this.ngbDatepickerI18n.ngOnDestroy();
    }
    if (this.ngbDateParserFormatter instanceof SsabNgbDateParserFormatter) {
      this.ngbDateParserFormatter.ngOnDestroy()
    }
    this.subscriptions.unsubscribe();
  }

  navigate(date: NgbDate) {
    this.navigationArrowClickEvent.emit(this.ngbDateParserFormatter.toDate(date));
  }

  fixWeekHeader(): void {
    /* Fix for week header, by default it's empty div without any value */
    const div = this.elRef.nativeElement.querySelector('.ngb-dp-showweek');
    if (div !== undefined && div !== null && this.ngbDatepickerI18n instanceof SsabNgbDatepickerI18n) {
      div.textContent = this.ngbDatepickerI18n.weekHeader;
    }
  }

  disableDates(dp: NgbDatepicker): void {
    if (!this.allowedDates || Object.keys(this.allowedDates).length <= 0) {
      return; // all dates are allowed
    }

    dp.markDisabled = (date: NgbDate) => {
      return this.allowedDates[this.ngbDateParserFormatter.toString(date)] === undefined;
    };
  }

  onChange(date: NgbDate) {
    if (date) {
      this.onChangeEvent.emit(this.ngbDateParserFormatter.toDate(date));
    }
  }

}
