import {RoutingConfig} from '@spartacus/core';
import {Router} from '@angular/router';

export const ssabRoutingConfig: RoutingConfig = {
  routing: {
    protected: true,
    routes: {
      product: { paths: ['product/:productCode/:name', 'product/:productCode'] },
      cart: { paths: ['cart']}
    }
  },
};

export function adjustRouterConfig(router: Router): any {
  const result = () => {
    // Same route can be 'reloaded'
    router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  };
  return result;
}
