import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ICON_TYPE} from '@spartacus/storefront';
import {SsabNotificationsAdapter} from '../../../shared/notifications/ssab-occ-notifications.adapter';
import {Observable} from 'rxjs';
import {take, filter} from 'rxjs/operators';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabNotification} from '../../../model/misc.model';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {OCC_USER_ID_ANONYMOUS, UserIdService} from "@spartacus/core";

@Component({
  selector: 'ssab-cx-notifications',
  templateUrl: './ssab-notifications.component.html',
})
export class SsabNotificationComponent implements OnInit {
  iconTypes = ICON_TYPE;
  notifications$: Observable<SsabNotification[]>;

  @Output() notificationsAmount = new EventEmitter<number>();

  constructor(private notificationsAdapter: SsabNotificationsAdapter,
              protected activeCartService: SsabActiveCartService,
              private userIdService: UserIdService) {
  }

  ngOnInit(): void {
    this.userIdService.takeUserId()
      .pipe(
        filter(uid => uid !== OCC_USER_ID_ANONYMOUS),
        take(1)
      )
      .subscribe((userId) => {
        this.notifications$ = this.notificationsAdapter.getNotifications(userId);
        this.notifications$
          .subscribe((notifications) =>
            this.notificationsAmount.emit(notifications.length !== null ? notifications.length : 0));
      });
  }

  dismiss(uid: string): void {
    this.userIdService.takeUserId()
      .pipe(
        filter(uid => uid !== OCC_USER_ID_ANONYMOUS),
        take(1)
      )
      .subscribe((userId) => {
        this.notifications$ = this.notificationsAdapter.dismissNotification(userId, uid);
      });
  }
}
