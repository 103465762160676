export const ssabOccContactSalesConfig = {
  backend: {
    occ: {
      endpoints: {
        listContactSalesProductLists: 'users/${userId}/contactsalesproductlist?fields=DEFAULT&customerEmulationParameter=true',
        createContactSalesProductList: 'users/${userId}/contactsalesproductlist/create?customerEmulationParameter=true'
      }
    }
  }
};

