import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ConverterService, OccEndpointsService} from '@spartacus/core';
import {OccCartEntryAdapter} from '@spartacus/cart/base/occ';
import {CART_NORMALIZER} from '@spartacus/cart/base/root';
import {SsabCart, SsabOrderEntry} from '../../model/cart.model';
import {SsabUnitOfMeasure} from "../../model/product.model";

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root',
})
export class SsabOccCartEntryAdapter extends OccCartEntryAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService
  ) {
    super(http, occEndpointsService, converterService);
  }

  public addEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry
  ): Observable<SsabCart> {
    const data = this.createOccEntry(entry);
    const url = this.occEndpointsService.buildUrl(
      'addEntries',
      {urlParams: {userId, cartId}}
    );

    return this.http
      .post<SsabCart>(url, JSON.stringify(data), {headers})
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<SsabCart>;
  }

  public addSsabEntries(userId: string, cartId: string, entries: SsabOrderEntry[]): Observable<SsabCart> {
    const data = entries.map((entry) => this.createOccEntry(entry));
    const url = this.occEndpointsService.buildUrl('multipleEntries', {
      urlParams: {userId, cartId},
    });
    return this.http
      .post<SsabCart>(url, JSON.stringify(data), {headers})
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<SsabCart>;
  }

  public updateEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry
  ): Observable<SsabCart> {
    const data = this.createOccEntry(entry);
    const url = this.occEndpointsService.buildUrl(
      'updateEntries',
      {urlParams: {userId, cartId, entryNumber: entry.entryNumber}}
    );

    return this.http
      .patch<SsabCart>(url, JSON.stringify(data), {headers})
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<SsabCart>;
  }

  private createOccEntry(entry: SsabOrderEntry): unknown {
    return {
      ...entry,
      doubleQuantity: entry.quantity,
      quantity: 1
    };
  }

  updateHeader(userId: string, cartId: string, cart: SsabCart, runErpSimulation?: boolean) {
    const url = this.occEndpointsService.buildUrl(
      'updateHeader',
      {urlParams: {userId, cartId}}
    );
    return this.http
      .put<SsabCart>(url, JSON.stringify(cart), {headers, params: {runErpSimulation}})
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<SsabCart>;
  }
}
