import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessage, GlobalMessageActions, GlobalMessageService, GlobalMessageType, HttpErrorModel, normalizeHttpError, Occ, SiteContextActions, Translatable, withdrawOn} from '@spartacus/core';
import {CartActions} from '@spartacus/cart/base/core';
import {from, Observable} from 'rxjs';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {openCloseSpinner} from '../../cms-components/shared/utils/functions/ssab-functions-utils';
import {SsabCart} from '../../model/cart.model';
import {SsabCartConnector} from './ssab-cart.connector';
import {SSAB_CART_ADD_ENTRIES, SSAB_CART_ADD_ENTRY, SSAB_CART_ADD_ENTRY_SUCCESS, SSAB_CART_UPDATE_ENTRY, SSAB_CART_UPDATE_HEADER, SSAB_CART_UPDATE_HEADER_SUCCESS, SSAB_PLACE_ORDER_FAIL, SsabCartAddEntries, SsabCartAddEntriesFail, SsabCartAddEntry, SsabCartAddEntrySuccess, SsabCartUpdateEntry, SsabUpdateCartHeader, SsabUpdateCartHeaderSuccess} from './ssab-entry.action';
import {SsabActiveCartService} from "./ssab-active-cart.service";
import CartModification = Occ.CartModification;

@Injectable()
export class SsabCartEffects {
  private contextChange$;
  addEntry$: Observable<| SsabCartAddEntrySuccess
    | CartActions.CartAddEntryFail
    | CartActions.LoadCart>;
  updateEntry$: Observable<| SsabCartAddEntrySuccess
    | CartActions.CartUpdateEntryFail
    | CartActions.LoadCart>;
  updateHeader$: Observable<| SsabUpdateCartHeaderSuccess | CartActions.CartUpdateEntryFail | CartActions.LoadCart>;
  refreshFromEntryUpdate$: Observable<CartActions.LoadCartSuccess>;
  refreshFromHeaderUpdate$: Observable<CartActions.LoadCartSuccess>;
  handlePlaceOrderFail$: Observable<GlobalMessageActions.AddMessage>;
  addEntries$: Observable<SsabCartAddEntrySuccess | SsabCartAddEntriesFail | CartActions.LoadCart>;


  constructor(
    protected actions$: Actions,
    protected cartConnector: SsabCartConnector,
    protected globalMessageService: GlobalMessageService,
    protected activeCartService: SsabActiveCartService
  ) {
    this.contextChange$ = this.actions$.pipe(
      ofType(
        SiteContextActions.CURRENCY_CHANGE,
        SiteContextActions.LANGUAGE_CHANGE
      )
    );


    this.addEntry$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_ADD_ENTRY),
      concatMap((action: SsabCartAddEntry) => {
        const payload = {
          userId: action.userId,
          cartId: action.cartId,
          productCode: action.entry.product.code,
          quantity: action.entry.quantity
        };
        return this.cartConnector
          .addEntry(
            action.userId,
            action.cartId,
            action.entry
          ).pipe(
            map(cart => {
                this.globalMessageService.add(
                  {
                    key: action.extraMessages?.successMessage ? action.extraMessages?.successMessage : 'ssab.addtocart.notification.success',
                  },
                  GlobalMessageType.MSG_TYPE_INFO
                );
                return new SsabCartAddEntrySuccess({
                  userId: action.userId,
                  cartId: action.cartId,
                  cart: cart
                });
              }
            ),
            catchError((error) => {
                openCloseSpinner(false);
                this.globalMessageService.add(
                  {
                    key: this.determineAddToCartErrorKey(action, normalizeHttpError(error)),
                    params: {codeProduct: action.entry.product.code}
                  },
                  GlobalMessageType.MSG_TYPE_ERROR,
                );
                return from([
                  new CartActions.CartAddEntryFail({
                    ...payload,
                    error: normalizeHttpError(error),
                  }),
                  new CartActions.LoadCart({
                    cartId: payload.cartId,
                    userId: payload.userId,
                  }),
                ]);
              }
            )
          )
          ;
      }),
      withdrawOn(this.contextChange$)
    ));

    this.addEntries$ =
      createEffect(() =>
        this.actions$.pipe(
          ofType(SSAB_CART_ADD_ENTRIES),
          concatMap((action: SsabCartAddEntries) => {
            const payload = {
              userId: action.userId,
              cartId: action.cartId,
              productCodes: action.entries?.map((entry) => entry.product.code),
            };
            return this.cartConnector.addSsabEntries(action.userId, action.cartId, action.entries).pipe(
              concatMap((cart) => {
                return [
                  new SsabCartAddEntrySuccess({
                    cartId: payload.cartId,
                    userId: payload.userId,
                    cart
                  })
                ];
              }),
              catchError((error) => {
                return from([
                  new SsabCartAddEntriesFail({
                    ...payload,
                    error: normalizeHttpError(error),
                  }),
                  new CartActions.LoadCart({
                    cartId: payload.cartId,
                    userId: payload.userId,
                  }),
                ]);
              }),
            );
          }),
          withdrawOn(this.contextChange$),
        ),
      );


    this.updateEntry$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_UPDATE_ENTRY),
      concatMap((action: SsabCartUpdateEntry) => {
        const payload = {
          userId: action.userId,
          cartId: action.cartId,
          productCode: action.entry.product.code,
          quantity: action.entry.quantity,
          entryNumber: action.entry.entryNumber + ''
        };
        return this.cartConnector
          .updateEntry(
            action.userId,
            action.cartId,
            action.entry
          )
          .pipe(
            map(cart => {
                let batchNote = action.entry?.batchItemNote;
                let successMessage = batchNote !== null && batchNote !== undefined ? 'ssab.update.product.batchnote.checkout.notification.success' : 'ssab.update.product.checkout.notification.success'
                this.globalMessageService.add(
                  {
                    key: action.extraMessages?.successMessage ? action.extraMessages?.successMessage : successMessage
                  },
                  GlobalMessageType.MSG_TYPE_INFO,
                );

                return new SsabCartAddEntrySuccess({
                  userId: action.userId,
                  cartId: action.cartId,
                  cart: cart
                });
              }
            ),
            catchError((error) => {
              this.globalMessageService.add(
                {
                  key: action.extraMessages?.errorMessage ? action.extraMessages?.errorMessage : 'ssab.update.product.checkout.notification.failed',
                  params: {codeProduct: action.entry.product.code}
                },
                GlobalMessageType.MSG_TYPE_ERROR
              );
              return from([
                new CartActions.CartUpdateEntryFail({
                  ...payload,
                  error: normalizeHttpError(error),
                }),
                new CartActions.LoadCart({
                  cartId: payload.cartId,
                  userId: payload.userId,
                }),
              ]);
            })
          );
      }),
      withdrawOn(this.contextChange$)
    ));


    this.updateHeader$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_UPDATE_HEADER),
      concatMap((action: SsabUpdateCartHeader) => {
        const payload = {userId: action.userId, cartId: action.cartId, productCode: '', quantity: 0, entryNumber: '0'};
        return this.cartConnector
          .updateHeader(
            action.userId,
            action.cartId,
            action.cart,
            action.runErpSimulation
          )
          .pipe(
            map(cart =>
              new SsabUpdateCartHeaderSuccess({
                userId: action.userId,
                cartId: action.cartId,
                cart: cart
              })
            ),
            catchError((error) =>
              from([
                new CartActions.CartUpdateEntryFail({
                  ...payload,
                  error: normalizeHttpError(error),
                }),
                new CartActions.LoadCart({
                  cartId: payload.cartId,
                  userId: payload.userId,
                }),
              ])
            )
          );
      }),
      withdrawOn(this.contextChange$)
    ));


    this.refreshFromEntryUpdate$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_ADD_ENTRY_SUCCESS),
      map((action: SsabCartAddEntrySuccess) => action.payload),
      withLatestFrom(this.activeCartService.getActiveCartId()),
      map(([payload, activeCartId]) => new CartActions.LoadCartSuccess({
          userId: payload.userId,
          cartId: payload.cartId,
          cart: payload.cart,
          // active cart means here the one used for checkout
          extraData: {active: (payload.cart as SsabCart).cartType == null && activeCartId == payload.cartId}
        })
      )
    ));


    this.refreshFromHeaderUpdate$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_UPDATE_HEADER_SUCCESS),
      map((action: SsabUpdateCartHeaderSuccess) => action.payload),
      map(payload =>
        new CartActions.LoadCartSuccess({userId: payload.userId, cartId: payload.cartId, cart: payload.cart, extraData: {active: true}})
      )
    ));


    this.handlePlaceOrderFail$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_PLACE_ORDER_FAIL),
      concatMap((payload: HttpErrorModel) => {
        const actions = [];
        openCloseSpinner(false);
        // Remove all other errors except type = error
        actions.push(new GlobalMessageActions.RemoveMessagesByType(GlobalMessageType.MSG_TYPE_WARNING));
        actions.push(new GlobalMessageActions.RemoveMessagesByType(GlobalMessageType.MSG_TYPE_CONFIRMATION));
        actions.push(new GlobalMessageActions.RemoveMessagesByType(GlobalMessageType.MSG_TYPE_INFO));
        // Delete all payload
        payload = null;
        // Add error message
        actions.push(new GlobalMessageActions.AddMessage({
          text: {key: 'ssab.cart.notification.orderFailed.timeout'} as Translatable,
          type: GlobalMessageType.MSG_TYPE_ERROR,
          timeout: 15000
        } as GlobalMessage));
        return actions;
      })
    ));
  }


  private determineAddToCartErrorKey(action: SsabCartAddEntry, error: any): string {
    if (action.extraMessages?.errorMessage) {
      return action.extraMessages?.errorMessage;
    }
    if (error?.details[0]?.type === 'InsufficientStockError') {
      return 'ssab.addtocart.notification.lowStock.failed';
    }
    if (error?.details[0]?.type === 'CommerceCartModificationError' && (error?.details[0]?.message as string).startsWith('ssab.addtocart')) {
      return error?.details[0]?.message;
    }
    return 'ssab.addtocart.notification.failed';
  }

}
