<ssab-floating-scrollbar>
  <div class="table-responsive"
       *ngIf="certificateDocuments && certificateDocuments.length > 0; else noCertificatesDocuments">
    <table class="table cx-documents-table">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.documents.search.'"
             [tableHeadType]="documentTypes.Certificate"
             (selectAllDocuments)="checkAllDocuments($event)">
      </thead>
      <tbody>
      <tr *ngFor="let certificate of certificateDocuments">
        <td>
          <div class="form-check mb-0 text-center">
            <input #selectedDocument
                   class="form-check-input position-relative"
                   type="checkbox" aria-label="..."
                   [checked]="selectAllDocuments"
                   (change)="onChange(selectedDocument.checked, certificate, getCertificateParams(certificate))">
          </div>
        </td>
        <td class="text-center">
          <i class="ssab-icon icon-system-download w-auto icon-cursor"
             (click)="onDownload(certificate, getCertificateParams(certificate))"></i>
        </td>
        <td>{{certificate.deliveryNumber}}</td>
        <td>{{certificate.orderNumber}}</td>
        <td>{{certificate.customerRef}}</td>
        <td>{{certificate.invoiceNumber}}</td>
        <td class="truncate">
          <span role="button"
                placement="bottom-left"
                container="body"
                popoverClass="popover-xs-max-width mt-2" [ngbPopover]="popTruncatedInfo" container="body"
                [autoClose]="'outside'"
                triggers="manual" (click)="toggleWithTruncatedInfo(popoverMaterialID,
                 certificate.materialId +(certificate.manufacturerMaterialId? ', '+certificate.manufacturerMaterialId:'')
                 )"
                #popoverMaterialID="ngbPopover">
            {{certificate.materialId}}
          </span>
          <ng-container *ngIf="certificate.manufacturerMaterialId">
            <br/>
            ({{certificate.manufacturerMaterialId}})
          </ng-container>
        </td>
        <td class="truncate">
          <span role="button"
                placement="bottom-left"
                container="body"
                popoverClass="popover-xs-max-width mt-2" [ngbPopover]="popTruncatedInfo" container="body"
                [autoClose]="'outside'"
                triggers="manual" (click)="toggleWithTruncatedInfo(popoverGrade, certificate.grade)"
                #popoverGrade="ngbPopover">
                {{certificate.grade}}
          </span>
        </td>
        <td>{{certificate.dimension}}</td>
        <td>{{certificate.documentDate ? (convertUIDate(certificate.documentDate)|cxDate: (getDateFormat()|async)) : ''}}</td>
        <td>{{certificate.sourcePlant}}</td>
        <td>{{certificate.deliveryDate ? (convertUIDate(certificate.deliveryDate)|cxDate: (getDateFormat()|async)) : ''}}</td>
        <td>
          {{certificate.certificateId}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ssab-floating-scrollbar>

<ng-template #noCertificatesDocuments>
  <div *ngIf="activeSort"
       class="empty-data-div row no-margin text-center">
    <div [ngClass]="'col-6'"
    >
      <h4
        class="text-primary">{{ 'ssab.documents.search.noDocuments.' + (documentTypes.Certificate | lowercase) | cxTranslate }}</h4>
    </div>
  </div>
</ng-template>

<ng-template #popTruncatedInfo let-info="info">
  <div class="text-default vertical-align-item">
    <span class="text-opacity">{{info}}</span>
  </div>
</ng-template>
