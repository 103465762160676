import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

@Component({
  selector: 'ssab-cart-item-consignment-label-dialog',
  templateUrl: 'ssab-cart-item-consignment-label-dialog.component.html'
})
export class SsabCartItemConsignmentLabelDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SsabCartItemConsignmentLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
