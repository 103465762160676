<form [formGroup]="sliderForm" #range>
  <div class="range-div" clickOutside (clickOutsideEvent)="clickout(range)">
    <label class="text-lg range-label range-control" (click)="toggleRangeContent($event); openRange()">
      {{sliderForm.controls.sliderControl.value[0]}} - {{sliderForm.controls.sliderControl.value[1]}}
      <i class="float-right ssab-icon icon-chevron-down"></i>
    </label>
    <div class="range-content row d-none no-gutters">
      <label *ngIf="!isTablet" class="text-lg col-12" (click)="toggleRangeContent($event)">{{'ssab.selectRange' | cxTranslate}}
        <i class="float-right ssab-icon icon-chevron-up"></i>
      </label>
      <div class="col-6 pr-1">
        <label class="text-bold">{{'common.from' | cxTranslate}}</label>
        <ssab-cx-input [typeInput]="'number'"
                       [idName]="'minQty_'+ idName"
                       [nameForm]="'rangeMin'"
                       [inputValue]="sliderForm.controls.sliderControl.value[0]"
                       (afterChange)="setValueFrom($event)"
                       [labelPlaceholder]="'common.min'"></ssab-cx-input>
      </div>
      <div class="col-6 pl-1">
        <label class="text-bold">{{'common.to' | cxTranslate}}</label>
        <ssab-cx-input [typeInput]="'number'"
                       [idName]="'maxQty_' + idName"
                       [nameForm]="'rangeMax'"
                       [inputValue]="sliderForm.controls.sliderControl.value[1]"
                       (afterChange)="setValueTo($event)"
                       [labelPlaceholder]="'common.max'"></ssab-cx-input>
      </div>
      <div class="col-12 pt-3 pb-5 pb-lg-3">
        <ngx-slider [options]="options" formControlName="sliderControl" class="ssab-slider"></ngx-slider>
      </div>
      <div class="col-6">
        <button class="btn btn-link btn-range-clear btn-default-height text-uppercase btn-block" (click)="resetForm(range)">
          {{ 'common.clear' | cxTranslate}}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary btn-default-height btn-block text-lg" (click)="toggleRangeContent(null, range, true)">
          {{ 'common.save' | cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</form>
