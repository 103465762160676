import {Classification, Facet, Occ, PaginationModel, Price, Product, Stock} from '@spartacus/core';
import {FacetList} from '@spartacus/storefront';
import FacetValue = Occ.FacetValue;
import {Sort} from "./misc.model";

export interface SsabProduct extends Product {
  productUnitConversions?: ProductUnitConversion[];
  stock?: SsabStock;
  unit?: SsabUnitOfMeasure;
  comparisonPrice?: Price;
  comparisonPriceEur?: Price;
  comparisonUnit?: SsabUnitOfMeasure;
  volume?: number;
  unitOfVolume?: SsabUnitOfMeasure;
  unitOfWeight?: SsabUnitOfMeasure;
  length?: number;
  width?: number;
  height?: number;
  unitOfDimensions?: SsabUnitOfMeasure;
  doubleQuantity?: number;
  price?: SsabPrice;
  packingProtection?: string;
  gtmClassifications?: Classification[];
  manufacturerTypeDescription?: string;
  nonSapProduct?: boolean;
  productForm?: string;
  productDataSheetUrl?: string;
  customerArticleIds?: string[];
  density8?: boolean;
  splitSurcharge?: SsabPrice;
  myFavorite?: boolean;
  suggestiveMode?: boolean;
  bundleSplitActivatedByDefault?: boolean;
  buttonAction?: ButtonAction;
}

export enum ButtonAction {
  addToCart = 'ADDTOCART',
  notifyMe = 'NOTIFYME',
  consignmentStock = 'CONSIGNMENTSTOCK',
  contactSales = 'CONTACTSALES'
}

export interface SsabUnitOfMeasure {
  code?: string;
  name?: string;
}

export interface ProductUnitConversion {
  unit?: SsabUnitOfMeasure;
  fromUnit?: SsabUnitOfMeasure;
  bundleSize?: string;
  bundleSizeTitle?: string;
  conversionToSalesUnit?: number;
  conversionToWeightUnit?: number;
  quantity?: number;
  lengthPerPiece?: number;
}

export interface SsabProductBatch {
  batchId: string;
  materialId: string;
  unit: SsabUnitOfMeasure;
  daysInStock: number;
  quantity?: number;
  addedToCart?: number;
  canSplitBatch?: boolean;
}

export interface StepperUpdateData {
  qty?: number;
  enterPressed?: boolean;
  unit?: string;
  GTMqty?: number;
  GTMevent?: string;
}

export interface QuantityUnitData {
  unit?: SsabUnitOfMeasure;
  qty?: number;
}

export interface SsabStock extends Stock {
  availableDate?: string;
  minimumAvailableQuantity?: number;
  bundleSize?: number;
  totalAmount?: number;
  commonStock?: boolean;
  consignment?: boolean;
  viewOnly?: boolean;
  stockDisabled?: boolean;
  name?: string;
  inventoryStatus?: string;
  stockLevelDouble?: number;
  batches?: SsabProductBatch[];
  warehouseCode?: string;
  stockInfoDisplay?: SsabStockInfoDisplay;
  canSplitBundle?: boolean;
  showNotifyMeIfOutOfStock?: boolean;
}

export interface SsabStockInfoDisplay {
  displayAmount?: boolean;
  label?: string;
  extraLabel?: string;
  status?: string;
}

export interface SsabPrice extends Price {
  customerSpecificPrice?: boolean;
  discountPrice?: boolean;
  scalePrice?: boolean;
}

export interface SsabFacet extends Facet {
  code?: string;
  range?: boolean;
  rangeMin?: number;
  rangeMax?: number;
  expanded?: boolean;
  search?: boolean;
  values?: SsabFacetValue[];
}

export interface SsabFacetValue extends FacetValue {
  code?: string;
}

export interface SsabFacetList extends FacetList {
  facets: SsabFacet[];
}

export interface SsabCoilSearchRequest {
  searchTerm: string;
  coilId: string;
  from: number;
  size: number;
  facets: SsabFacet[];
  sorts: Sort[];
}

export interface SsabCoilSearchResult {
  searchTerm: string;
  coilId: string;
  pagination: PaginationModel;
  coils: SsabCoil[];
  facets: SsabFacet[];
  sorts: Sort[];
}

export interface SsabCoil {
  coilId: string;
  product: string;
  productName: string;
  colorCode: string;
  colorRgb: string;
  colorName: string;
  dimensions: string;
  shiptToAddress: string;
  productionTime: string;
  gradeAndMetalCoating: string;
  paintBatch: string;
}

export interface SsabCoilComparisonRequest {
  referenceCoil: string;
  compareCoils: string[];
}

export interface SsabCoilComparisonResponse {
  referenceCoil: SsabCoil;
  comparison: SsabCoilComparison[];
}

export interface SsabCoilComparison {
  coil: SsabCoil;
  comparisonResult: SsabCoilComparisonResult[];
}

export interface SsabCoilComparisonResult {
  id: string;
  match: string;
  value: object;
  diff: object;
}
