import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabOrderEntry} from '../../../../model/cart.model';
import {QuantityUnitData, SsabUnitOfMeasure, StepperUpdateData} from '../../../../model/product.model';
import {SsabProductService} from '../../../../service/product/ssab-product.service';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({
  selector: '[ssab-cx-collected-item]',
  templateUrl: './ssab-collected-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabCollectedItemComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  @Input() item: SsabOrderEntry;
  @Input() units: SsabUnitOfMeasure[];
  @Input() isMobile: boolean;
  @Input() tableScroll: Observable<any>;
  @Output() removeEntry = new EventEmitter<SsabOrderEntry>();
  @Output() unitQuantityUpdate = new EventEmitter<QuantityUnitData>();
  @Input() minQuantity = 1;
  protected quantityUpdated$ = new BehaviorSubject<number>(0);
  subscriptions: Subscription = new Subscription();

  @ViewChild('unitsSelect') unitsSelect: NgSelectComponent;

  constructor(
    protected productService: SsabProductService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService
  ) {
  }

  postBackQuantity(updatedQuantity: StepperUpdateData, unit: SsabUnitOfMeasure): void {
    if (updatedQuantity.qty) {
      this.unitQuantityUpdate.emit({unit: unit, qty: updatedQuantity.qty});
      this.quantityUpdated$.next(updatedQuantity.qty);
    }
  }

  postBackUnit(unit: SsabUnitOfMeasure, updatedQuantity: number): void {
    if (unit?.code) {
      this.unitQuantityUpdate.emit({unit, qty: updatedQuantity});
    }
  }

  ngOnInit(): void {
    this.quantityUpdated$.next(this.item.quantity);
    this.subscriptions.add(
      this.productService.getCatalogAvailability(this.item.product, this.item.unit).subscribe(
        stock => {
          this.minQuantity = stock.minimumAvailableQuantity;
          this.cdr.detectChanges();
        }
      )
    );

    this.subscriptions.add(
      this.tableScroll?.subscribe(
        event => {
          if (event) {
            this.closeDropdown();
          }
        }
      )
    );
  }

  removeItem(entry: SsabOrderEntry): void {
    this.removeEntry.emit(entry);
  }

  closeContactSalesModal(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.COLLECTED_ITEMS);
    this.launchDialogService.closeDialog('closed');
  }

  showHideContent($event: MouseEvent, hideAll?: boolean): void {
    const parentElement = ($event.target as HTMLElement).closest('.collected-item');
    const collectedItems = parentElement.closest('.modal-body').querySelectorAll('.collected-item');
    const info = parentElement.querySelector('.collected-item-info');
    const content = parentElement.querySelector('.collected-item-content');
    const modalHeader = document.getElementById('collected-item-header');
    if (hideAll) {
      collectedItems.forEach(el => el.classList.add('d-none'));
      parentElement.classList.remove('d-none');
    } else {
      collectedItems.forEach(el => el.classList.remove('d-none'));
    }

    info.classList.toggle('d-none');
    content.classList.toggle('d-none');
    modalHeader.classList.toggle('d-none');
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  closeDropdown(): void {
    this.unitsSelect?.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
