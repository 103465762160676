import {NgModule} from '@angular/core';
import {CmsConfig, provideConfig} from '@spartacus/core';
import {SMART_EDIT_FEATURE, SmartEditConfig, SmartEditRootModule} from '@spartacus/smartedit/root';

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [SMART_EDIT_FEATURE]: {
          module: () =>
            import('@spartacus/smartedit').then((m) => m.SmartEditModule),
        },
      },
    }),
    provideConfig(<SmartEditConfig>{
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        allowOrigin: 'localhost:9002,api-dev-shop.ssab.com:443,admin-dev-shop.ssab.com:443,api-test-shop.ssab.com:443,admin-test-shop.ssab.com:443,api-shop.ssab.com:443,admin-shop.ssab.com:443,cdns.gigya.com:443'
      },
    })
  ]
})
export class SmartEditFeatureModule {
}
