import {Component, OnDestroy, OnInit} from '@angular/core';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {AuthHttpHeaderService, GlobalMessageService, GlobalMessageType, WindowRef} from '@spartacus/core';
import {SsabAuthService} from '../../service/user-auth/ssab-auth.service';
import {SsabSessionExpiredDialogData} from './ssab-session-expired-layout.config';

@Component({
  selector: 'ssab-cx-session-expired',
  templateUrl: './ssab-session-expired.component.html',

})
export class SsabSessionExpiredComponent implements OnInit, OnDestroy{
  iconTypes = ICON_TYPE;
  private tokenExpired: any;
  private fiveMinutesInMiliseconds = 300000; // close modal notification in 5 minutes

  constructor(protected ssabAuthService: SsabAuthService,
              protected winRef: WindowRef,
              protected authHttpHeaderService: AuthHttpHeaderService,
              protected globalMessageService: GlobalMessageService,
              protected launchDialogService: LaunchDialogService) {
  }

  ngOnDestroy(): void {
    clearTimeout(this.tokenExpired);
    this.closeModal();
  }

  ngOnInit(): void {

    this.tokenExpired = setTimeout(() => {
      this.closeModal();
      this.authHttpHeaderService.handleExpiredRefreshToken();
      this.globalMessageService.add(
        {
          key: 'ssab.session.expired.error',
        },
        GlobalMessageType.MSG_TYPE_ERROR,
        9999999999
      );
      clearTimeout(this.tokenExpired);
    }, (this.fiveMinutesInMiliseconds));
  }

  public confirm(): void {
    this.ssabAuthService.refreshToken();
    clearTimeout(this.tokenExpired);
    this.launchDialogService.closeDialog('close');
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SESSION_EXPIRED, undefined, {
      sessionUpdated: true
    } as SsabSessionExpiredDialogData);
    this.closeModal();
  }

  public closeModal(): void{
    this.launchDialogService.clear(LAUNCH_CALLER.SESSION_EXPIRED);
    this.launchDialogService.closeDialog('closed');
  }
}
