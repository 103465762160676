import {
  BaseOccUrlProperties,
  BaseSiteService,
  OccConfig,
  OccEndpointsService, WindowRef,
} from '@spartacus/core';
import {Injectable, Optional} from '@angular/core';
import {GlobalLogin} from '../../model/misc.model';

@Injectable({
  providedIn: 'root',
})
export class SsabOccEndpointsService extends OccEndpointsService{

  constructor(
    private aConfig: OccConfig,
    @Optional() private aBaseSiteService: BaseSiteService,
    protected winRef: WindowRef
  ) {

    super(aConfig, aBaseSiteService);
  }

  /**
   * Returns base OCC endpoint (baseUrl + prefix + baseSite) base on provided values
   *
   * @param baseUrlProperties Specify properties to not add to the url (baseUrl, prefix, baseSite)
   */
  getBaseUrl(
    baseUrlProperties: BaseOccUrlProperties = {
      baseUrl: true,
      prefix: true,
      baseSite: true,
    }
  ): string {
    const baseUrl = super.getBaseUrl(baseUrlProperties);
    const baseUrlParameters = baseUrl.split('/');
    if (baseUrlParameters[baseUrlParameters.length - 1] !== this.getLocalStorage().getItem(GlobalLogin.BaseSiteActive)
      && this.getLocalStorage().getItem(GlobalLogin.BaseSiteActive) !== null
      && this.aConfig.context.baseSite.includes(baseUrlParameters[baseUrlParameters.length - 1])){
      this.aBaseSiteService.setActive(this.getLocalStorage().getItem(GlobalLogin.BaseSiteActive));
      return baseUrl.replace(baseUrlParameters[baseUrlParameters.length - 1], this.getLocalStorage().getItem(GlobalLogin.BaseSiteActive));
    }
    return baseUrl;
  }

  protected getLocalStorage(): Storage {
    return this.winRef.localStorage;
  }
}
