import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConverterService, OccEndpointsService, OccProductSearchAdapter, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductSearchPage, SearchConfig} from "@spartacus/core";
import {SsabSearchConfig} from "../../../model/misc.model";

@Injectable()
export class SsabProductSearchAdapter extends OccProductSearchAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
    super(http, occEndpoints, converter);
  }

  search(
    query: string,
    searchConfig: SearchConfig = this.DEFAULT_SEARCH_CONFIG
  ): Observable<ProductSearchPage> {
    // this breaks ASM
    /*   const context = new HttpContext().set(OCC_HTTP_TOKEN, {
         sendUserIdAsHeader: true,
       });*/

    return this.http
      .get(this.getSearchEndpoint(query, searchConfig), {/*context*/})
      .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER));
  }

  protected getSearchEndpoint(query: string, searchConfig: SsabSearchConfig): string {
    let url = super.getSearchEndpoint(query, searchConfig);
    if (searchConfig.disableSpinner) {
      url = url.replace('&spinner=true', '');
    }
    return url;
  }

}
