import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {provideDefaultConfig,} from '@spartacus/core';
import {SsabOccCartEntryAdapter} from "./ssab-occ-cart-entry.adapter";
import {SsabOccCartNormalizer} from "./ssab-occ-cart-normalizer";
import {ssabOccCartConfig} from "./ssab-occ-cart-config";
import {SsabActiveCartService} from "./ssab-active-cart.service";
import {SsabMultiCartService} from "./ssab-multi-cart.service";
import {EffectsModule} from "@ngrx/effects";
import {SsabCartEffects} from "./ssab-cart.effect";
import {SsabCartConnector} from "./ssab-cart.connector";
import {SsabOccOrderNormalizer} from "./ssab-order.normalizer";
import {SsabCartAdapter} from './ssab-cart.adapter';
import {SsabWishListService} from './ssab-wish-list.service';
import {ActiveCartService, CartAdapter, CartEntryAdapter, CartEntryConnector, MultiCartService} from '@spartacus/cart/base/core';
import {ORDER_NORMALIZER} from '@spartacus/order/root';
import {ActiveCartFacade, CART_NORMALIZER, MultiCartFacade} from '@spartacus/cart/base/root';
import {SsabWishListFacade} from './ssab-wish-list.facade';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([SsabCartEffects])
  ],
  providers: [
    provideDefaultConfig(ssabOccCartConfig),
    {
      provide: MultiCartService,
      useClass: SsabMultiCartService
    },

    {
      provide: MultiCartFacade,
      useClass: SsabMultiCartService,
    },
    {
      provide: CartEntryConnector,
      useClass: SsabCartConnector,
    },
    {
      provide: CART_NORMALIZER,
      useExisting: SsabOccCartNormalizer,
      multi: true,
    },
    {
      provide: CartEntryAdapter,
      useClass: SsabOccCartEntryAdapter,
    },
    {
      provide: CartAdapter,
      useClass: SsabCartAdapter
    },
    {
      provide: ActiveCartService,
      useClass: SsabActiveCartService
    },
    {
      provide: ActiveCartFacade,
      useClass: SsabActiveCartService
    },
    {
      provide: ORDER_NORMALIZER,
      useExisting: SsabOccOrderNormalizer,
      multi: true
    },
    {
      provide: SsabWishListFacade,
      useClass: SsabWishListService
    }
  ],
})
export class SsabCartOccModule {
}
