<ng-container  *ngIf="product$ | async as product">

  <ssab-cx-product-intro
    [product]="product"
  ></ssab-cx-product-intro>

  <span class="product-detail-summary">

  <ssab-cx-product-images [product]="product"></ssab-cx-product-images>

  <div class="summary">
    <ssab-cx-product-price [product]="product" #productPrice></ssab-cx-product-price>
    <div class="cx-description pb-3" *ngIf="product.description">
      <h4 class="text-primary pb-3">{{ 'productDetails.description' | cxTranslate }}</h4>
      <div class="text-paragraph-md" [innerHTML]="product.description"></div>
    </div>
    <a href="{{product.productDataSheetUrl}}"
       target="_blank" rel="noopener noreferrer"
       *ngIf="product.productDataSheetUrl"
       class="product-data-sheet-link">
      <i class="ssab-icon icon-general-PDF icon-inline mr-3"></i>
      {{'productDetails.dataSheet' | cxTranslate}}
    </a>
  </div>

  <ssab-cx-product-attributes
    [product]="product"
  ></ssab-cx-product-attributes>
</span>

</ng-container>
