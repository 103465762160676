import {Injectable} from '@angular/core';
import {ProductListComponentService, ProductListRouteParams, ViewConfig} from '@spartacus/storefront';
import {CurrencyService, LanguageService, RoutingService} from '@spartacus/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SsabSearchConfig, SsabSearchCriteria} from '../../model/misc.model';
import {HttpClient} from '@angular/common/http';
import {SsabProductConnector} from './ssab-product.connector';
import {saveAs} from 'file-saver';
import {SsabGtmService} from '../analytics/gtm/ssab-gtm.service';
import {GTMEvent} from '../../model/gtm/gtm-product.model';
import {SsabProductSearchService} from "./search/ssab-product-search.service";

@Injectable({
  providedIn: 'root',
})
export class SsabProductListComponentService extends ProductListComponentService {

  constructor(
    protected productSearchService: SsabProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected http: HttpClient,
    protected productConnector: SsabProductConnector,
    protected gtmService: SsabGtmService,
    protected viewConfig: ViewConfig,
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router, viewConfig);
  }

  getCriteriaFromRoute(
    routeParams: ProductListRouteParams,
    queryParams: SsabSearchCriteria,
    pageSize?: number,
    previousPageSize?: number
  ): SsabSearchCriteria {
    return {
      query: queryParams.query || this.getQueryFromRouteParams(routeParams),
      pageSize: queryParams.pageSize || (pageSize > 0 ? pageSize : this.viewConfig.view.defaultPageSize),
      currentPage: queryParams.currentPage,
      sortCode: queryParams.sortCode,
      previousPageSize: queryParams.previousPageSize || (previousPageSize > 0 ? previousPageSize : (pageSize > 0 ? pageSize : this.viewConfig.view.defaultPageSize))
    };
  }

  protected search(criteria: SsabSearchCriteria): void {
    const currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;
    const previousPageSize = criteria.previousPageSize;

    this.productSearchService.search(
      criteria.query,
      Object.assign(
        {},
        currentPage && {currentPage},
        previousPageSize && {previousPageSize},
        pageSize && {pageSize},
        sort && {sort},
        {suggestiveMode: true}
      ) as SsabSearchConfig
    );
  }

  downloadStockAvailability(pageSize: number, pageNumber: number, sort: string): void {

    this.routing
      .getRouterState()
      .subscribe((route) => {
        const routeCriteria = this.getCriteriaFromRoute(
          route.state.params,
          route.state.queryParams,
          pageSize
        );
        const criteria = {
          ...routeCriteria,
          currentPage: pageNumber,
          sort
        };
        this.productConnector
          .getStockAvailability(criteria)
          .subscribe((data) => this.downloadFile(data));
        this.gtmService.pushStockAvailabilityDownload(GTMEvent.StockFunctionality, criteria, pageSize);
      })
      .unsubscribe();
  }

  downloadFile(data: any): void {
    const csv = new Blob([data.body], {
      type: 'text/plain' // must match the Accept type
    });
    saveAs(csv, data.headers.get('content-disposition').split('filename=')[1]);
  }
}
