import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Observable, Subscription} from "rxjs";
import {SsabCoilComparisonResponse} from "../../model/product.model";
import {TablesTypes} from "../../model/misc.model";
import {DateUtils} from "../../shared/util/date-utils";
import {SsabGlobalconfigurationService} from "../../service/general/configuration/ssab-globalconfiguration.service";
import {ICON_TYPE, LaunchDialogService} from "@spartacus/storefront";
import {SsabCoilsComparisonService} from "../../service/product/ssab-coils-comparison.service";
import {WindowRef} from '@spartacus/core';


@Component({
  selector: 'ssab-cx-coils-comparison',
  templateUrl: './ssab-coils-comparison.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCoilsComparisonComponentComponent implements OnDestroy, OnInit {
  subscriptions = new Subscription();

  compareResult$: Observable<SsabCoilComparisonResponse>;

  tableHeadType = TablesTypes.Coils;

  iconTypes = ICON_TYPE;

  constructor(
    protected coilsComparisonService: SsabCoilsComparisonService,
    protected launchDialogService: LaunchDialogService,
    private globalConfigurationService: SsabGlobalconfigurationService,
    protected actions$: Actions,
    protected windowRef: WindowRef,
  ) {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe(() => {
        this.compareResult$ = this.coilsComparisonService.getCompareCoils(0, 10)
      })
    );
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }

  removeFromComparison(coilId: string) {
    this.coilsComparisonService.removeCoilFromComparison(coilId);
  }

  close(): void {
    this.launchDialogService.closeDialog('closed');
  }

  download(): void {
    this.windowRef.nativeWindow.print();
  }

  printDate() {
    return new Date();
  }
}
