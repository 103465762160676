<div
  *ngIf="(loggedIn$ | async)"
  class="ssab-icon"
  (click)="toggle()"
  [class.is-active]="isExpanded | async"
  [ngClass]="{'d-none hamburger-close': (isExpanded | async),
              'icon-navigation-menu': !(isExpanded | async)}"
  [attr.aria-expanded]="isExpanded | async"
  aria-label="Menu"
  aria-controls="header-account-container, header-categories-container, header-locale-container">
</div>
