<div *ngIf="productCode && getSsabProduct().suggestiveMode===false" (submit)="addToCart()" class="row no-margin" [ngClass]="!isPLPView ? 'pdp-add': ''">
  <ng-container *ngIf="user$ | async as user">
    <ng-container *ngIf="showQuantityAndUnits()">
      <div class="quantity " [ngClass]="isPLPView ?  'col-auto' : 'col-auto col-lg-5 col-xxl-4 col-no-padding'"
           *ngIf="showQuantity">
        <ssab-cx-item-stepper
          [isValueChangeable]="true"
          [showButtons]="true"
          [max]="maxQuantity"
          [min]="minQuantity"
          [value]="getValue()"
          [step]="minQuantity"
          [stepperTimeout]="0"
          [id]="product.code+'_stepper'"
          (update)="quantityChange($event)"
          class="stepper-price"
        ></ssab-cx-item-stepper>
      </div>

      <div class="units mx-lg-auto ml-auto" [ngClass]="isPLPView ? 'col-auto' : 'col-auto col-lg-3 col-xxl-2'">
        <ssab-unit-picker-cart
          *ngIf="showQuantity"
          [selectedUnit]="selectedUnit"
          [product]="getSsabProduct()"
          (update)="unitChange($event)"
        ></ssab-unit-picker-cart>

        <div class="previous-orders-entry-out-of-stock" *ngIf="reorderTextKey">
          <span>{{ reorderTextKey|cxTranslate }}</span>
        </div>
      </div>
    </ng-container>


    <!-- Add to cart button -->
    <!-- prio: if canShowAddToCart (in stock) else if canShowConsignmentStock (in stock) else if canShowContactSales (no permission or no stock) else if nostock - show notifyMe -->
    <ng-container #addToCartButton *ngIf="showAddtoCart(); else noAddToCart">
      <div class="add-to-cart"
           [ngClass]="isPLPView ? 'col' : 'col-no-padding col col-12 col-lg-4 col-xl-4 col-xxl-6 pt-4 pt-lg-0'">
        <button
          class="btn btn-primary btn-block btn-default-height"
          type="submit"
          [disabled]="quantity <= 0 || (quantity > maxQuantity && maxQuantity!==null ) || !hasStock"
          (click)="addToCart()">
          <h6 class="mb-0">
            {{ 'addToCart.addToCart' | cxTranslate | uppercase }}
          </h6>
        </button>
      </div>
    </ng-container>
    <ng-template #noAddToCart>
      <ng-container *ngIf="showConsignmentStock(); else notifyMeButton">
        <div class="justify-content-md-end mt-md-3 mt-lg-0 ml-auto col-md-auto col-12 col-no-padding">
          <button
            class="btn btn-primary btn-block btn-default-height"
            type="submit"
            (click)="openConsignmentList()">
            <h6 class="mb-0">
              {{ 'ssab.consignment.list.button' | cxTranslate | uppercase }}
            </h6>
          </button>
        </div>
      </ng-container>

      <ng-template #notifyMeButton>
        <ng-container *ngIf="showNotifyMe(); else noCheckoutButtonsPossible">
          <div class="justify-content-md-end mt-md-3 mt-lg-0 ml-auto col-md-auto col-12 col-no-padding">
            <button class="btn btn-primary btn-default-height col-md-auto col-12 pr-3" type="submit"
                    (click)="subscribeToAvailability()">
              <i class="ssab-icon icon-social-mail icon-inline mr-2"></i>
              <h6 class="mb-0 d-inline">{{ 'ssab.notifyme.productavailability.text' | cxTranslate | uppercase }}</h6>
            </button>
          </div>
        </ng-container>

        <ng-template #noCheckoutButtonsPossible>
          <div class="add-to-cart"
               [ngClass]="isPLPView ? 'col' : 'col-no-padding col col-12 col-lg-4 col-xxl-6 pt-3 pt-lg-0'"
               *ngIf="showContactSales()">
            <button
              class="btn btn-tertiary btn-default-height btn-block"
              type="submit"
              (click)="contactSales()">
              <h6 class="mb-0">{{ 'ssab.savedCart.ContactSalesProductList.button' | cxTranslate | uppercase }}</h6>
            </button>
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
    <ng-container *ngIf="this.getSsabProduct().stock?.canSplitBundle">
      <div class="col-12 mt-3 p-0 text-right" *ngIf="showAddtoCart()">
        <ssab-cx-bundle-split-toggle
          [label]="'ssab.user.bundleSplit.toggle' + (this.getSsabProduct().splitSurcharge!==undefined ? '' : 'NoSurcharge')"
          [isSplitBundle]="isSplitBundle"
          (updateSplitBundle)="setSplitBundle($event)"></ssab-cx-bundle-split-toggle>
      </div>
    </ng-container>

  </ng-container>

</div>
