import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GlobalMessageService, GlobalMessageType, LanguageService} from '@spartacus/core';
import {DateUtils} from '../../../../shared/util/date-utils';
import {EloquaForms, Sort} from '../../../../model/misc.model';
import {SsabDocolProduct} from '../../../../model/docol.model';
import {DocumentTypes, SsabDocumentIdentifier} from '../../../../model/document.model';
import {catchError} from 'rxjs/operators';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {Subscription} from 'rxjs';
import {openCloseSpinner} from '../../../shared/utils/functions/ssab-functions-utils';
import {saveAs} from 'file-saver';
import {SsabGtmService} from '../../../../service/analytics/gtm/ssab-gtm.service';
import {GTMEvent} from '../../../../model/gtm/gtm-product.model';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'ssab-cx-material-data-table',
  templateUrl: './ssab-material-data-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabMaterialDataTableComponent implements OnInit, OnDestroy {

  @Input() materials: SsabDocolProduct[];
  @Input() activeSort: Sort;
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() downloadDocument = new EventEmitter<SsabDocolProduct>();
  elqUrlWithParameters = '';
  documentTypes = DocumentTypes;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected language: LanguageService,
    protected cdr: ChangeDetectorRef,
    protected userService: SsabUserService,
    protected globalMessageService: GlobalMessageService,
    protected gtmService: SsabGtmService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.getElOQUABaseUrl(EloquaForms.DocolDownload).subscribe((elqBaseUrl) => {
        this.elqUrlWithParameters = elqBaseUrl;
        this.cdr.detectChanges();
      })
    );
  }

  sort(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  onDownload(docolProduct: SsabDocolProduct, documentType: string, materialGrade?: string): void {

    const documentToDownload = this.getParams(docolProduct, documentType);

    const sub = this.userService.downloadDocument(documentToDownload, true)
      .pipe(
        catchError(err => this.documentErrorHandler(sub))
      )
      .subscribe((data) => {
        this.downloadFile(data);
        this.pushGTMEvent(documentType, materialGrade);
        if (this.elqUrlWithParameters) {
          let params = new HttpParams();
          params = params.set('id', docolProduct.id);
          params = params.set('grade', docolProduct.grade);
          params = params.set('coating', docolProduct.coating);
          params = params.set('type', docolProduct.type);
          params = params.set('yieldStress', docolProduct.yieldStress.toString());
          params = params.set('testDirection', docolProduct.testDirection);
          params = params.set('standards', docolProduct.standards);
          params = params.set('documentDate', (new Date()).toLocaleString());
          const subEloqua = this.userService
            .sendELOQUAData(this.elqUrlWithParameters, params)
            .subscribe(() => subEloqua.unsubscribe());
        }
        sub.unsubscribe();
      });
  }

  pushGTMEvent(GTMDocumentType?: string, materialGrade?: string): void {
    switch (GTMDocumentType) {
      case DocumentTypes.DocolEngStress:
        this.gtmService.pushMaterialDataDownload(GTMEvent.MaterialData, DocumentTypes.GTMEngStress, materialGrade);
        break;
      case DocumentTypes.DocolFlp:
        this.gtmService.pushMaterialDataDownload(GTMEvent.MaterialData, DocumentTypes.GTMFormingLimit, materialGrade);
        break;
      case DocumentTypes.DocolMaterialData:
        this.gtmService.pushMaterialDataDownload(GTMEvent.MaterialData, DocumentTypes.GTMMaterialCard, materialGrade);
        break;
    }
  }

  getParams(docolProduct: SsabDocolProduct, documentType: string): SsabDocumentIdentifier {
    switch (documentType) {
      case this.documentTypes.DocolFlp:
        return {
          docType: this.documentTypes.DocolProduct,
          documentId: docolProduct.fldDocId,
          documentSource: 'mfiles',
          version: docolProduct.fldVersion,
          fileId: +docolProduct.fldFileId
        } as SsabDocumentIdentifier;
        break;
      case this.documentTypes.DocolEngStress:
        return {
          docType: this.documentTypes.DocolProduct,
          documentId: docolProduct.engStressDocId,
          documentSource: 'mfiles',
          version: docolProduct.engStressVersion,
          fileId: +docolProduct.engStressFileId
        } as SsabDocumentIdentifier;
        break;
      case this.documentTypes.DocolMaterialData:
        return {
          docType: this.documentTypes.DocolProduct,
          documentId: docolProduct.mtbDocId,
          documentSource: 'mfiles',
          version: docolProduct.mtbVersion,
          fileId: +docolProduct.mtbFileId
        } as SsabDocumentIdentifier;
        break;
    }

    return null;
  }

  downloadFile(data: any): void {
    if (data) {
      const blob = new Blob([data.body], {type: data.headers.get('content-type')});
      const fileName = data.headers.get('content-disposition') ? data.headers.get('content-disposition').split('filename=')[1].replace(/"/g, '') : 'document';
      const file = new File([blob], fileName, {type: data.headers.get('content-type')});
      saveAs(file);
    }
  }

  documentErrorHandler(sub: Subscription) {
    openCloseSpinner(false);
    this.globalMessageService.add(
      {
        key: 'ssab.download.failed',
      },
      GlobalMessageType.MSG_TYPE_ERROR
    );
    sub.unsubscribe();
    return null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

