<mat-dialog-content class="dialog-content">
  <p class="dialog-title">{{'ssab.update.product.batchnote.dialog.header' | cxTranslate}}</p>
  <mat-form-field class="dialog-form-field">
    <input matInput [(ngModel)]="data" [maxLength]="200" placeholder="{{'ssab.update.product.batchnote.dialog.placeholder' | cxTranslate}}">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onNoClick()">{{'common.cancel' | cxTranslate}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{'common.confirm' | cxTranslate}}</button>
</mat-dialog-actions>
