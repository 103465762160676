import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2,} from '@angular/core';
import {Router} from '@angular/router';
import {SsabNavigationNode} from "../../../model/cms.model";
import {HamburgerMenuService, NavigationNode, NavigationUIComponent} from "@spartacus/storefront";
import {ProductSearchConnector, WindowRef} from '@spartacus/core';
import {map, take} from "rxjs/operators";
import {SsabFacet, SsabFacetValue} from "../../../model/product.model";
import {Observable, of} from "rxjs";
import {SsabSearchConfig} from "../../../model/misc.model";

/**
 * override of the original Spartacus NavigationUIComponent
 * added logic: conditional stop event propagation in toggleOpen(event: UIEvent): void function
 * added logic: clickout(event) function
 */

@Component({
  selector: 'ssab-cx-navigation-ui',
  templateUrl: './ssab-navigation-ui.component.html',
})
export class SsabNavigationUiComponent extends NavigationUIComponent implements OnDestroy, OnInit {

  @Input() stripNarrowAvailable: boolean = false;
  @Input() consignmentAvailable: boolean = false;
  @Input() otherServicesAvailable: boolean = false;
  node$: Observable<SsabNavigationNode>;
  myAccountNode: boolean;
  showNavigationNode: boolean;

  constructor(
    protected aRouter: Router,
    protected aRenderer: Renderer2,
    protected aElemRef: ElementRef,
    protected hamburgerMenuService: HamburgerMenuService,
    protected aWinRef: WindowRef,
    protected productSearchConnector: ProductSearchConnector,
    protected cdr: ChangeDetectorRef,
  ) {
    super(aRouter, aRenderer, aElemRef, hamburgerMenuService, aWinRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  @Input() set currentNode(node: SsabNavigationNode) {
    this.myAccountNode = (node?.uid && node?.uid === 'MyAccountComponent');
    this.setShowNavigationNode(node?.uid);
    this.filterBrands(node);
  }

  private filterBrands(node: SsabNavigationNode): void {
    const uid = (node as any)?.uid;
    if (uid === 'TopLinksCategoryNavNode' || uid === 'BrandsNavigationComponent') {
      this.node$ = this.productSearchConnector.search('*', {pageSize: 1, disableSpinner: true, suggestiveMode: true} as SsabSearchConfig)
        .pipe(
          take(1),
          map(result => result.facets
            ?.filter((f: SsabFacet) => f.code === 'productBrand' || f.code === 'productForm')
            .flatMap(f => f.values)
            .filter(fv => fv.count > 0)
            .map((fv: SsabFacetValue) => fv.code.toLowerCase())
          )
        ).pipe(map(brands => {
          let n = node;
          const headerNav = uid === 'BrandsNavigationComponent';
          if (headerNav) {
            n = n.children[0];
          }
          return this.filterAllowedBrands(brands, {...n}, !headerNav);
        }));
    } else {
      this.node$ = of(node);
    }
  }

  private filterAllowedBrands(filters: string[], node: SsabNavigationNode, rootLevel: boolean): SsabNavigationNode {
    if (node.children) {
      node.children = node.children
        ?.map(child => this.filterAllowedBrands(filters, child, false));
      if (!rootLevel) {
        node.children
          .reverse() // prefer original order, just move inactive ones in the back
          .sort((a, b) =>
            !a.hide && a.url.indexOf('product') >= 0 ? -1 : !b.hide || a.url.indexOf('product') < 0 ? 1 : 0
          );
      }
      return {...node, hide: false};
    }
    let ret = this.checkFilter(filters, node, ':productBrand:');
    if (ret === null) {
      ret = this.checkFilter(filters, node, ':productForm:');
    }
    if (ret === null) {
      return node;
    }
    return ret;
  }

  checkFilter(filters: string[], node: SsabNavigationNode, name: string): SsabNavigationNode {
    const idx = node.url?.indexOf(name);
    if (idx > 0) {
      const filter = (node.url as string).substring(idx + name.length).toLowerCase().replace('%20', ' ');
      if (filters?.find(b => b === filter) === undefined) {
        return {...node, hide: true, type: name.replace(/\:/g, '')};
      }
    }
    return null;
  }

  toggleOpen(event: UIEvent): void {
    const siteContext = document.querySelectorAll('ssab-cx-site-context-selector .context-select.navigation-icon-active');
    siteContext.forEach(context => {
      (context as HTMLElement).click();
    })

    if (this.winRef.nativeWindow?.location.href.includes('global')) {
      // calling the super method outside of the global base store resulted in a bug when trying to navigate to the same page you are on.
      // Example : navigate to my account page. Open menu and go again to my account page (Menu stays open and doesn't close)
      super.toggleOpen(event);
    }

    const node = (<HTMLElement>event.currentTarget);
    if (node.classList.contains('is-open')) {
      node.classList.remove('is-opened');
    } else if (!node.classList.contains('nolink')) {
      this.closeAllSubmenus();
    }
    node.classList.toggle('is-open');

    if (!this.myAccountNode) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }


  closeAllSubmenus(): void {
    const allNavElements: NodeListOf<HTMLElement> = document.querySelectorAll("nav");
    for (let i = 0; i < allNavElements.length; i++) {
      this.aRenderer.removeClass(allNavElements[i], 'is-open');
      this.aRenderer.removeClass(allNavElements[i], 'is-opened');
    }
  }

  setShowNavigationNode(nodeID: string): void {
    if (nodeID === 'StripNarrowNavNode' && !this.stripNarrowAvailable) {
      this.showNavigationNode = false;
    } else if (nodeID === 'ConsignmentStockNavNode' && !this.consignmentAvailable) {
      this.showNavigationNode = false;
    } else if (nodeID === 'OtherServicesNavNode' && !this.otherServicesAvailable) {
      this.showNavigationNode = false;
    }
    this.showNavigationNode = true;
  }

  closeIfClickedTheSameLink(navNode: NavigationNode): void {
    if (
      typeof navNode.url === 'string' &&
      this.winRef.nativeWindow?.location.href.includes(navNode.url)
    ) {
      this.aElemRef.nativeElement
        .querySelectorAll('nav.is-open:not(.back), nav.is-opened')
        .forEach((el: any) => {
          this.aRenderer.removeClass(el, 'is-open');
          this.aRenderer.removeClass(el, 'is-opened');
        });
      this.reinitializeMenu();

    }
  }

}
