import {Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {ExternalJavaScript} from "../../model/external-javascript.model";
import {BehaviorSubject, Observable, Observer, Subject} from "rxjs";
import {SsabCdcConfig} from "../cdc/ssab-cdc-config";

@Injectable({providedIn: 'root'})
export class SsabScriptService {

  private scripts: ExternalJavaScript[] = [];
  private renderer: Renderer2;
  private cdcScriptLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private rendererFactory: RendererFactory2,
              private cdcConfig: SsabCdcConfig) {

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadCdcScript(apiKey: string): Observable<ExternalJavaScript> {
    return this.load(this.getCdcScriptUrl(apiKey));
  }

  getCdcScriptUrl(apiKey: string) {
    return this.cdcConfig.cdc.javascript.baseUrl + apiKey;
  }

  public load(url: string): Observable<ExternalJavaScript> {
    return new Observable<ExternalJavaScript>((observer: Observer<ExternalJavaScript>) => {
      const existingScript: ExternalJavaScript = this.scripts.find(script => script.src === url);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        this.cdcScriptLoaded.next(true);
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script
        const script: ExternalJavaScript = {src: url, loaded: false};
        this.scripts = [...this.scripts, script];

        // Load the script
        const scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.src = script.src;

        scriptElement.onload = () => {
          // Script loaded successfully
          this.cdcScriptLoaded.next(true);
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          // Script loading error
          observer.error("Couldn't load script: " + script.src);
        };

        this.renderer.appendChild(document.head, scriptElement);
      }
    });
  }

  public isCdcScriptLoaded(): Observable<boolean> {
    return this.cdcScriptLoaded.asObservable();
  }

}
