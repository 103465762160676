import {SsabProduct, SsabUnitOfMeasure, StepperUpdateData} from '../../../../../model/product.model';
import {Component, Input, OnInit} from '@angular/core';
import {SsabOrderEntry} from '../../../../../model/cart.model';
import {SsabCartItemBaseComponent} from '../ssab-cart-item.base.component';
import {SsabActiveCartService} from "../../../../../service/cart/ssab-active-cart.service";
import {CartItemContextSource} from "@spartacus/cart/base/components";
import {ActivatedRouterStateSnapshot} from "@spartacus/core";
import {Observable} from "rxjs";


@Component({
  selector: 'ssab-cx-cart-item-consignment',
  templateUrl: './ssab-cart-item-consignment.component.html',
})
export class SsabCartItemConsignmentComponent extends SsabCartItemBaseComponent implements OnInit {
  @Input() items: SsabOrderEntry[];

  batchesID: string[] = [];
  protected routeState$: Observable<ActivatedRouterStateSnapshot>;

  constructor(protected activeCartService: SsabActiveCartService,
              protected cartItemContextSource: CartItemContextSource
  ) {
    super(activeCartService, cartItemContextSource);
  }

  ngOnInit(): void {
    this.items.forEach((item) => {
      if (item.splitBundle) {
        this.addRemoveBatch(item);
      }
    });
  }

  getProduct(): SsabProduct[] {
    return [this.items[0].product];
  }

  quantityChange(updatedQuantity: StepperUpdateData, item: SsabOrderEntry, splitBundle: boolean = null): void {
    if (updatedQuantity.qty) {
      this.activeCartService?.updateSsabEntry({
        product: {code: item?.product?.code},
        entryNumber: item.entryNumber,
        quantity: 1,
        unit: item.unit as SsabUnitOfMeasure,
        batchId: item.batchId,
        splitBatch: [{batchId: item.batchId, splitBatchQuantity: updatedQuantity.qty}],
        splitBundle: splitBundle
      } as SsabOrderEntry);
    }
  }

  addRemoveBatch(item: SsabOrderEntry) {
    if (!this.isSplitBatchOnList(item.batchId)) {
      this.batchesID.push(item.batchId);
    }
  }

  updateEntry(item: SsabOrderEntry, addRemove: boolean): void {
    if (addRemove) {
      this.addRemoveBatch(item);
      this.quantityChange({qty: item.product?.stock?.bundleSize} as StepperUpdateData, item, true);
    } else {
      this.batchesID = this.batchesID
        .filter(function (batch) {
          return batch !== item.batchId;
        });
      this.quantityChange({qty: item.maxBatchQuantity} as StepperUpdateData, item, false);
    }
  }

  isSplitBatchOnList(batchId: string): boolean {
    return this.batchesID.some(el => el === batchId);
  }

  getMaxQuantity(item: SsabOrderEntry): number {
    return item.maxBatchQuantity < item.product.stock?.stockLevel ? item.maxBatchQuantity : item.product.stock?.stockLevel;
  }
}
