import {Component} from '@angular/core';
import {
  GlobalMessageEntities, GlobalMessageService, GlobalMessageType
} from '@spartacus/core';
import { Observable } from 'rxjs';
import {GlobalMessageComponent, ICON_TYPE} from '@spartacus/storefront';

@Component({
  selector: 'ssab-cx-global-message',
  templateUrl: './ssab-global-message.component.html',
})
export class SsabGlobalMessageComponent extends  GlobalMessageComponent{
  iconTypes = ICON_TYPE;
  messageType: typeof GlobalMessageType = GlobalMessageType;

  constructor(protected globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }
}
