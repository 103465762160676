import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {Observable} from 'rxjs';
import {SsabCoil, SsabCoilComparisonResponse} from "../../model/product.model";
import {SsabCoilsComparisonService} from "../../service/product/ssab-coils-comparison.service";


@Component({
  selector: 'ssab-cx-coils-comparison-preview',
  templateUrl: './ssab-coils-comparison-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCoilsComparisonPreviewComponent implements OnInit {
  coils$: Observable<SsabCoilComparisonResponse>;

  constructor(
    protected coilsComparisonService: SsabCoilsComparisonService,
    protected launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {
    this.coils$ = this.coilsComparisonService.getCompareCoils(0, 9999);
  }

  remove(coil: SsabCoil): void {
    this.coilsComparisonService.removeCoilFromComparison(coil.coilId);
  }

  clear(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.COILS_CLEAR, undefined);
  }

  compare(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.COILS_COMPARISON, undefined);
  }

}
