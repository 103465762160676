<div [ngClass]="isPLPView? '': 'cx-price-pdp'">
  <div [ngClass]="currentStock?.viewOnly ? 'pb-5' : 'pb-4'" *ngIf="!isPLPView; else plpPrice">
    <div class="row" *ngIf="showPricesOrConsignmentPricesStock$ | async">
      <ng-container *ngIf="!product.nonSapProduct">
        <div class="col-12 text-right">
          <ng-container *ngTemplateOutlet="comparisonPrice"></ng-container>
        </div>
        <ng-template #noExistingPrice>
          <h3 *ngIf="showConsignmentPricesStock" class="col-12 text-right"> {{ 'ssab.product.stock.inStock.noPrice.consignment' |cxTranslate }}</h3>
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="stock;context:{ css:'pt-3', isPDP : true}"></ng-container>
  </div>

  <ng-template #comparisonPrice>
    <ssab-cx-product-comparison-price
      [product]="product"
      [currentPrice]="currentPrice"
      [selectedUnit]="selectedUnit?.unit">
    </ssab-cx-product-comparison-price>
  </ng-template>


  <ng-template #plpPrice>
    <div class="row cx-price-plp">
      <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
        <div class="text-right col-12" aria-label="Product price" *ngIf="!product.nonSapProduct && currentPrice else noExistingPrice">
          <ng-container *ngTemplateOutlet="comparisonPrice"></ng-container>
        </div>
        <ng-template #noExistingPrice>
          <div class="text-right col-12" aria-label="Product price">
            <h3 *ngIf="showConsignmentPricesStock"> {{ 'ssab.product.stock.inStock.noPrice.consignment' |cxTranslate }}</h3>
          </div>
        </ng-template>
      </ng-container>
      <div class="col-12 cx-price-stock">
        <ng-container *ngTemplateOutlet="stock;context:{ css:'', isPDP : false}"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #stock let-css="css" let-isPDP="isPDP">
    <div class="{{css}}" [ngClass]="{'row stock-info-container': isPDP}">
      <ng-container *ngIf="isPDP">
        <div class="col-12 col-lg-6 row no-gutters" [ngClass]="{'pt-3': !currentStock?.viewOnly}">
          <span class="col-12">{{ currentStock.name }}</span>
          <span *ngIf="!currentStock?.viewOnly" class="col-12 order-first order-lg-last">
            {{ 'ssab.product.delivery.tolerance' |cxTranslate }}
          </span>
        </div>
      </ng-container>
      <ng-container>
        <ng-container *ngIf="!isPDP">
          <div class="{{css}} text-right">
            <ng-container *ngTemplateOutlet="stockLevels"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="isPDP">
          <div class="{{css}} col-12 col-lg-6 text-right order-first order-lg-last" [ngClass]="{
             'traffic-light':  !currentStock?.viewOnly,
             'traffic-light':  currentStock?.viewOnly && !(showConsignmentPricesStock)}">
            <ng-container *ngTemplateOutlet="stockLevels"></ng-container>
          </div>
        </ng-container>
      </ng-container>
      <span *ngIf="!isPDP">{{ currentStock.name }}</span>
    </div>
  </ng-template>


  <ng-template #stockLevels>
    <div class="text-bold text-right"
         [ngClass]="!currentStock.stockInfoDisplay?.status ? '': 'cx-stock-level-'+ currentStock.stockInfoDisplay?.status">
      <i class="ssab-icon icon-inline icon-dot mr-2 pb-1" *ngIf="currentStock.stockInfoDisplay?.status">&nbsp;</i>
      <span [ngClass]="{'text-capitalize-first d-inline-block ': currentStock.stockInfoDisplay?.status}" [innerHTML]="currentStock.stockInfoDisplay?.label">
        </span>
    </div>
    <div class="text-right text-bold text-capitalize-first" *ngIf="currentStock.stockInfoDisplay?.extraLabel">
      {{ currentStock.stockInfoDisplay?.extraLabel }}
    </div>
  </ng-template>

  <ssab-cx-add-to-cart
    [showQuantity]="true"
    [selectedUnit]="selectedUnit?.unit.code"
    [product]="product"
    [minQuantity]="currentStock.minimumAvailableQuantity"
    [stockLevel]="currentStock.stockLevel"
    [currentStock]="currentStock"
    (update)="onChange($event)"
    [isPLPView]="isPLPView"
    [isSplitBundle]="product.bundleSplitActivatedByDefault"
  ></ssab-cx-add-to-cart>
  <ng-container *ngIf="currentPrice?.scalePrice">
    <div class="text-delayed text-sm text-right pt-2" *ngIf="baseSite$ | async as baseSite">
      {{ 'ssab.product.price.scale' | cxTranslate }}
    </div>
  </ng-container>
</div>

