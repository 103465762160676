<div *ngIf="notifications$ | async as notifications">
  <div
    class="alert alert-notification"
    *ngFor="
      let notification of notifications;
    "
  >
    <div class="container">
      <span [innerHTML]=" notification.content | safeHtml "> </span>
      <button
        class="close"
        type="button"
        (click)="dismiss(notification.uid)"
      >
        <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>
</div>
