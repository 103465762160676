<ng-container *ngIf="coils$ | async as coilCompare">
  <div class="list" *ngIf="coilCompare.referenceCoil || coilCompare.comparison?.length > 0">
    <div class="list-content">
      <div class="d-flex flex-column flex-lg-row">
        <div class="list-reference align-self-stretch align-self-lg-end">
          <h6 class="list-content-header reference">{{ 'ssab.coil.comparison.preview.reference.label' | cxTranslate }}</h6>
          <div class="items-container">
            <ng-container *ngIf="coilCompare.referenceCoil;else showEmptyReference">
              <ng-container *ngTemplateOutlet="coilTemplate;context:{ coil:coilCompare.referenceCoil, idx:'reference'}"></ng-container>
            </ng-container>
            <ng-template #showEmptyReference>
              {{ 'ssab.coil.comparison.preview.reference.description' | cxTranslate }}
            </ng-template>
          </div>
        </div>
        <div class="list-products flex-grow align-self-stretch align-self-lg-end">
          <h6 class="list-content-header comparison">{{ 'ssab.coil.comparison.preview.comparison.label' | cxTranslate }}</h6>
          <div class="items-container">
            <ng-container *ngIf="coilCompare.comparison.length > 0;else showEmpty">
              <ng-container *ngFor="let coil of coilCompare.comparison; index as idx">
                <ng-container *ngTemplateOutlet="coilTemplate;context:{ coil:coil.coil, idx:idx}"></ng-container>
              </ng-container>
            </ng-container>
            <ng-template #showEmpty>
              {{ 'ssab.coil.comparison.preview.comparison.description' | cxTranslate }}
            </ng-template>
            <div class="fade-div">
              <div class="fade-right"></div>
            </div>
          </div>
        </div>
        <div class="list-buttons align-self-stretch align-self-lg-end">
          <button (click)="compare()" class="btn btn-primary btn-sm btn-block" type="submit" [disabled]="coilCompare.comparison.length===0">
            {{ 'ssab.coil.comparison.preview.compare' | cxTranslate }}
          </button>
          <button (click)="clear()" class="btn btn-link text-uppercase btn-block" type="submit">
            {{ 'ssab.coil.comparison.preview.clear' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #coilTemplate let-coil="coil" let-idx="idx">
  <div class="item">
    <div class="d-flex flex-row h-100 justify-content-between">
      <div class="d-flex align-items-center">
        <div class="cx-product-image-container">
          <img src="assets/coil-comparison/images/coil.jpg"/>
        </div>
        <div class="product-name-wrapper">
          <h5 class="mb-1">{{ coil.coilId }}</h5>
          <div>{{ coil.dimensions }}</div>
          <div *ngIf="idx === 'reference'">{{ coil.productName }} |
            <span class="text-space-nowrap ml-2">
              <i *ngIf="coil.colorRgb" class="ssab-icon icon-color-box icon-sm icon-inline mr-2" style="background-color: rgb({{ coil.colorRgb }})"></i>
              <span>{{ coil.colorName }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="">
        <button class="btn btn-icon m-0 p-1" (click)="remove(coil)">
          <span class="ssab-icon icon-system-trash icon-sm"></span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
