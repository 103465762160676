import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Options} from '@angular-slider/ngx-slider';
import {WindowRef} from '@spartacus/core';

@Component({
  selector: 'ssab-cx-input-range',
  templateUrl: './ssab-input-range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabInputRangeComponent implements OnInit {
  @Input() inputValueFrom: number;
  @Input() inputValueTo: number;
  @Input() inputValueStep: number;
  @Input() idName: string;
  @Input() sliderForm: UntypedFormGroup;
  @Output() inputRangeOpen = new EventEmitter<boolean>();
  @Output() inputRangeClosed = new EventEmitter<boolean>();

  options: Options;
  valueFrom: number;
  valueTo: number;
  isTablet: boolean;

  constructor(protected winRef: WindowRef) {
  }


  ngOnInit(): void {
    this.options = {
      floor: this.inputValueFrom,
      ceil: this.inputValueTo,
      step: this.inputValueStep
    };
    this.valueFrom = this.sliderForm.controls.sliderControl.value ? this.sliderForm.controls.sliderControl.value[0] : null;
    this.valueTo = this.sliderForm.controls.sliderControl.value ? this.sliderForm.controls.sliderControl.value[1] : null;
    this.setForm();
    this.isTablet =  this.winRef.nativeWindow.matchMedia('only screen and (max-width: 1020px)').matches;
  }

  setForm(): void {
    if(!this.sliderForm.controls.sliderControl.value){
      this.resetForm();
    }
  }

  resetForm(element?: Element): void{
    this.sliderForm.controls.sliderControl.setValue([this.inputValueFrom, this.inputValueTo]);
    if(element != null){
      this.closeRange();
      this.toggleRangeContent(null, element, true);
    }
  }

  setValueFrom($event: KeyboardEvent): void {
    this.valueFrom = parseFloat(($event.target as HTMLInputElement).value);
    this.sliderForm.controls.sliderControl.setValue([this.valueFrom, this.valueTo ? this.valueTo : this.inputValueTo]);
  }

  setValueTo($event: KeyboardEvent): void{
    this.valueTo = parseFloat(($event.target as HTMLInputElement).value);
    this.sliderForm.controls.sliderControl.setValue([this.valueFrom ? this.valueFrom : this.inputValueFrom, this.valueTo]);
  }

  toggleRangeContent($event: MouseEvent, element?: Element, forceClose?: boolean): void {
    const rangeLabel = element
      ? element.querySelector('.range-label')
      : ($event.target as HTMLElement).closest('.range-div').querySelector('.range-label');
    const rangeContent = element
      ? element.querySelector('.range-content')
      : ($event.target as HTMLElement).closest('.range-div').querySelector('.range-content');
    rangeLabel.classList.toggle('d-none');
    rangeContent.classList.toggle('d-none');
    if (forceClose ) {
      this.closeRange();
      rangeLabel.classList.remove('d-none');
      rangeContent.classList.add('d-none');
    }
  }

  clickout(element?: Element): void {
    if(!this.isTablet){
      this.toggleRangeContent(null, element, true);
    }
  }

  openRange(): void{
    this.inputRangeOpen.emit(true);
  }

  closeRange(): void{
    this.inputRangeClosed.emit(true);
  }
}
