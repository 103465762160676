import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ConverterService, OccConfig, OccEndpointsService, OccProductAdapter, OccRequestsOptimizerService} from '@spartacus/core';
import {Injectable} from '@angular/core';
import {ssabOccProductConfig} from './ssab-occ-product-config';
import {SsabCoilComparisonRequest, SsabCoilComparisonResponse, SsabCoilSearchRequest, SsabCoilSearchResult, SsabProduct, SsabStock, SsabUnitOfMeasure} from '../../model/product.model';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SsabSearchCriteria} from '../../model/misc.model';

@Injectable({
  providedIn: 'root',
})
export class SsabOccProductAdapter extends OccProductAdapter {
  endpoints: OccConfig;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
  ) {
    super(http, occEndpoints, converter, requestsOptimizer);
    this.endpoints = ssabOccProductConfig;
  }

  getCatalogPrice(product: SsabProduct, unit: SsabUnitOfMeasure, quantity: number): Observable<SsabProduct> {
    const url = this.occEndpoints.buildUrl('catalogPrice', {urlParams: {productCode: product.code, unit: unit.code, quantity}});
    return this.http.get<SsabProduct>(url);
  }

  getCatalogAvailability(product: SsabProduct, unit: SsabUnitOfMeasure): Observable<SsabStock> {
    const url = this.occEndpoints.buildUrl('catalogAvailability', {urlParams: {productCode: product.code, unit: unit.code}});
    return this.http.get<SsabStock>(url).pipe(
      tap(stock => stock.stockLevel = (stock as any).stockLevelDouble)
    );
  }

  getStockAvailabilityDownload(criteria: SsabSearchCriteria): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/plain');
    headers = headers.set('Content-Type', 'text/plain; charset=utf-8');
    const url = this.occEndpoints.buildUrl('stockAvaliabilityDownload', {urlParams: {headers}, queryParams: criteria});
    return this.http.get(url, {responseType: 'blob', observe: 'response'});
  }

  searchCoils(request: SsabCoilSearchRequest): Observable<SsabCoilSearchResult> {
    const url = this.occEndpoints.buildUrl('coilSearch');
    return this.http.post<SsabCoilSearchResult>(url, request);
  }

  compareCoils(request: SsabCoilComparisonRequest): Observable<SsabCoilComparisonResponse> {
    const url = this.occEndpoints.buildUrl('coilCompare');
    return this.http.post<SsabCoilComparisonResponse>(url, request);
  }
}
