import {BaseSiteService, WindowRef, LanguageService} from '@spartacus/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {SsabGlobalConfiguration} from './spartacus/features/model/misc.model';
import {filter, take, withLatestFrom} from 'rxjs/operators';
import {SsabScriptService} from './spartacus/features/service/script/ssab-script.service';
import {SsabGtmService} from './spartacus/features/service/analytics/gtm/ssab-gtm.service';
import {Subscription} from 'rxjs';
import {
  SsabGlobalconfigurationService
} from './spartacus/features/service/general/configuration/ssab-globalconfiguration.service';
import {SsabI18nextTranslationService} from "./spartacus/features/shared/i18/ssab-i18next-translation.service";


declare global {
  interface Window {
    OneTrust: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ssabstorefront';

  isTopWindow = true;
  subscriptions = new Subscription();

  constructor(
    protected ssabGtmService: SsabGtmService,
    protected baseSiteService: BaseSiteService,
    private gtmService: GoogleTagManagerService,
    private globalConfiguration: SsabGlobalconfigurationService,
    private aWinRef: WindowRef,
    protected scriptService: SsabScriptService,
    protected languageService: LanguageService,
    protected ssabTranslationService: SsabI18nextTranslationService,
  ) {
  }

  ngOnInit(): void {

    const dataLayer = window['dataLayer'] || {};
    this.gtmService.pushTag(dataLayer);

    //We check if the window is in an iframe to prevent malicious clickjacking
    //Smartedit will add cx-preview to the url and we want to allow it to open the page in an iframe
    if (window != top && !window.location.toString().includes('cx-preview')){
      this.isTopWindow = false;
    }
    this.handleGlobalConfigurations();

  }

  protected handleGlobalConfigurations(): void {
    this.subscriptions.add(
      this.globalConfiguration.getGlobalConfiguration()
        .pipe(take(1))
        .subscribe((config: SsabGlobalConfiguration) => {
        if (this.isTopWindow) {
          this.createElqScript(config);
          this.createOneTrustScript(config);
          this.createCdcScript(config);
        }
      })
    );
  }

  protected createElqScript(config: SsabGlobalConfiguration): void{
    if(config.elqSiteID){
      const script = document.createElement('script'); // create a script tag
      script.setAttribute('type', 'text/javascript'); // set type to js
      script.setAttribute('src', config.elqSrc); // define src for script tag
      script.async = true;
      this.aWinRef.document.head.prepend(script);
    }
  }

  protected createOneTrustScript(config: SsabGlobalConfiguration): void {
    if (config.oneTrust) {
      const script = document.createElement('script'); // create a script tag
      const scriptFunction = document.createElement('script');
      script.setAttribute('type', 'text/javascript'); // set type to js
      script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');// define src for script tag
      script.setAttribute('charset', 'UTF-8');
      script.setAttribute('data-document-language', 'true');
      script.setAttribute('data-domain-script', config.oneTrustDomain);
      script.setAttribute('id', 'oneTrustScript');
      scriptFunction.innerHTML = 'function OptanonWrapper() { }';
      scriptFunction.setAttribute('id', 'oneTrustFunction');
      this.aWinRef.document.head.prepend(script, scriptFunction); // load script into document head, or change this to a specific location
    }
  }

  protected createCdcScript(config: SsabGlobalConfiguration): void {
    this.subscriptions.add(
      this.scriptService.loadCdcScript(config.cdcApiKey).subscribe()
    );
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    const elementClassName = event.target.className;
    const element = event.target;

    // this code is based on documentation from One trust for Single Page Applications
    // https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
    if (elementClassName.includes('ot-sdk-show-settings')) {
      const otConsentSdk = document.getElementById('onetrust-consent-sdk');
      if (otConsentSdk) {
        const otFilter = otConsentSdk.querySelector('.onetrust-pc-dark-filter');
        otFilter.setAttribute('style', '');
      }

      const oneTrust = window.OneTrust;
      if (oneTrust != null) {
        oneTrust.Init();
        setTimeout(function() {
          oneTrust.LoadBanner();
          oneTrust.InitializeBanner();
          oneTrust.ToggleInfoDisplay();
          const otPCSdk = otConsentSdk.querySelector('#onetrust-pc-sdk');
          if (otPCSdk) {
            //Sometimes duplicate modals will display on one another so we hide the unwanted modal
            const oneTrustWindows = document.querySelectorAll('#onetrust-pc-sdk');
            if(oneTrustWindows.length > 1){
              oneTrustWindows[oneTrustWindows.length - 1].setAttribute('style', 'display:none;');
            }
          }
        }, 500);
      }
    }

    if (elementClassName.includes('conditions-file')) {
      this.baseSiteService.getActive().pipe(
        filter(Boolean),
        withLatestFrom(this.languageService.getActive()),
        take(1)).subscribe(([baseSite, language]) => {
        let filePDF = (event.target as HTMLElement).getAttribute('data-file' + '-' + baseSite);
        filePDF = filePDF ? ('assets/pdf/' + filePDF) : element.dataset.url;
        if (!filePDF && element.dataset.filePrefix) {
          window.open('assets/pdf/' + element.dataset.filePrefix + "_" + language + ".pdf", '_blank');
        } else {
          this.openTermsAndConditions(language, filePDF);
        }
      }).unsubscribe();
    }

    //function that checks all clicks for finding from where user finds a product
    if (elementClassName.includes('gtmData') && element.dataset.product !== undefined) {
      this.ssabGtmService.pushProductClick(element.dataset.product,
        (element.dataset.componentname !== undefined ? element.dataset.componentname :
          (element.dataset.pagename !== undefined ? element.dataset.pagename : null)),
        (element.dataset.position !== undefined ? (Number(element.dataset.position) + 1) : null));

    }
  }

  protected openTermsAndConditions(language: string, filePDF: string): void {
    this.ssabTranslationService.translate("ssab.terms.and.conditions.key")
      .pipe(
        withLatestFrom(this.globalConfiguration.getGlobalConfiguration())
      )
      .subscribe(([key, config]) => {
        if (config.showTermsAndConditionsPage) {
          window.open(key, '_blank');
        } else {
          window.open(filePDF, '_blank');
        }
      }).unsubscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
