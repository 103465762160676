import {AuthService} from '@spartacus/core';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SsabCurrentProductService} from '../../../service/product/ssab-current-product.service';
import {SsabProduct} from '../../../model/product.model';
import {AddToWishListComponent} from '@spartacus/cart/wish-list/components/add-to-wishlist';
import {OrderEntry} from '@spartacus/cart/base/root';
import {SsabWishListFacade} from '../../../service/cart/ssab-wish-list.facade';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'ssab-cx-add-to-wishlist',
  templateUrl: './ssab-add-to-wish-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabAddToWishListComponent extends AddToWishListComponent implements OnInit {

  @Input() product: SsabProduct;

  favorite: boolean;

  constructor(
    protected wishListService: SsabWishListFacade,
    protected currentProductService: SsabCurrentProductService,
    protected authService: AuthService
  ) {
    super(wishListService, currentProductService, authService);
  }

  ngOnInit(): void {
    this.favorite = this.product.myFavorite;
  }

  toggle(): void {
    this.favorite = !this.favorite;
    this.wishListService.addSsabEntry(this.product, !this.favorite);
  }

}
