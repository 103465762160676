import {Component, OnDestroy, OnInit} from '@angular/core';
import {SsabCdcService} from "../../../../service/cdc/ssab-cdc.service";
import {LanguageService} from "@spartacus/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-sso-login',
  templateUrl: './ssab-sso-login.component.html'
})
export class SsabSsoLoginComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  language: string;

  constructor(protected cdcService: SsabCdcService, protected languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.languageService.getActive().subscribe(
        l => this.language = l
      )
    );
  }

  ssoLogin(): void {
    this.cdcService.ssoLogin(this.language);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
