import {Injectable, OnDestroy} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse,
} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {openCloseSpinner} from '../functions/ssab-functions-utils';
import {BaseSiteService, UserIdService, WindowRef} from '@spartacus/core';

@Injectable({providedIn: 'root'})
export class OccFunctionRequestInterceptor implements HttpInterceptor, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    protected lUserIdService: UserIdService,
    protected baseSiteService: BaseSiteService,
    protected winRef: WindowRef) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addEmulatedCustomerParameter(request) != null ? this.addEmulatedCustomerParameter(request) : request;
    if (this.isSpinnerFunction(request.url)) {
      openCloseSpinner(true);
      return next.handle(request).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            openCloseSpinner(false);
          }
          catchError(err => {
            console.error(err);
            openCloseSpinner(false);
            return of();
          })
        })
      );
    }

    return next.handle(request);
  }

  isSpinnerFunction(url: string): boolean {
    const spinnerQueryParameter = 'spinner=true';
    return url.includes(spinnerQueryParameter);
  }

  /*
    * For ASM Purposes - in order to get components and search results for Emulated users
    * Adds customerEmulation={user.uid} to request as a parameter for
    *  (1) /products/search
    *  (2) /cms/pages
    *  (3) /cms/components
    *  (4) /products
   */
  addEmulatedCustomerParameter(request: HttpRequest<any>): HttpRequest<any> {
    let userID;
    const customerEmulationParameter = 'customerEmulationParameter=true';

    if (request.url.includes(customerEmulationParameter)) {
      this.subscriptions.add(
        this.lUserIdService.getUserId().subscribe(id => {
          if (id != null) {
            userID = id;
          }
        })
      );
      if (userID !== undefined) {
        return request.clone({
          setParams: {
            customerEmulation: userID
          }
        });
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
