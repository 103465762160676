import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {SiteContextUrlSerializer, WindowRef} from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class SsabUrlService {

  constructor(private location: Location, private serializer: SiteContextUrlSerializer, private winRef: WindowRef) {
  }

  public getBaseUrlWithContextParams(): string {
    const path: string = this.location.path(true);
    const {params} = this.serializer.urlExtractContextParameters(path);
    const basePath = Object.values(params).join('/');
    const href = this.winRef.document.location.href;
    return href.slice(0, href.indexOf(basePath) + basePath.length);
  }
}
