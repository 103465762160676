import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PageMetaResolver, PRODUCT_NORMALIZER, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductAdapter, ProductConnector, ProductOccModule, ProductSearchAdapter, ProductSearchService, ProductService, provideDefaultConfig} from '@spartacus/core';
import {ssabOccProductConfig} from "./ssab-occ-product-config";
import {SsabProductService} from "./ssab-product.service";
import {SsabOccProductAdapter} from "./ssab-occ-product.adapter";
import {SsabProductConnector} from "./ssab-product.connector";
import {SsabOccProductSearchPageNormalizer} from "./search/ssab-occ-product-search-page-normalizer";
import {CurrentProductService, ProductFacetService, ProductListComponentService} from '@spartacus/storefront';
import {SsabCurrentProductService} from './ssab-current-product.service';
import {SsabProductStockNormalizer} from "./ssab-product-stock.normalizer";
import {SsabProductPageMetaResolver} from './ssab-product-page-meta.resolver';
import {SsabProductFacetService} from './ssab-product-facet.service';
import {SsabProductListComponentService} from './ssab-product-list-component.service';
import {SsabProductSearchService} from './search/ssab-product-search.service';
import {SsabProductSearchAdapter} from "./search/ssab-product-search.adapter";

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    provideDefaultConfig(ssabOccProductConfig),
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: SsabProductStockNormalizer,
      multi: true,
    },
    {
      provide: ProductService,
      useClass: SsabProductService
    },
    {
      provide: ProductSearchService,
      useClass: SsabProductSearchService
    },
    {
      provide: CurrentProductService,
      useClass: SsabCurrentProductService
    },
    {
      provide: ProductAdapter,
      useClass: SsabOccProductAdapter
    },
    {
      provide: ProductConnector,
      useClass: SsabProductConnector
    },
    {
      provide: ProductFacetService,
      useClass: SsabProductFacetService
    },
    {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useExisting: SsabOccProductSearchPageNormalizer,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: SsabProductPageMetaResolver,
      multi: true,
    },
    {
      provide: ProductListComponentService,
      useClass: SsabProductListComponentService
    },
    {
      provide: ProductSearchAdapter,
      useClass: SsabProductSearchAdapter,
    },
  ],
})
export class SsabProductOccModule extends ProductOccModule {
}
