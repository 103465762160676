import { Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {Observable} from 'rxjs';
import {SsabCMSBannerGridComponent} from '../../../model/cms.model';
import {filter, map} from 'rxjs/operators';
import {CmsService, ContentSlotComponentData} from '@spartacus/core';
@Component({
  selector: 'ssab-cx-banner-grid',
  templateUrl: './ssab-banner-grid.component.html'
})
export class SsabBannerGridComponent{
  private componentData$: Observable<SsabCMSBannerGridComponent> = this.componentData.data$.pipe(
    filter(Boolean)
  );

  items$: Observable<
    Observable<ContentSlotComponentData>[]
    > = this.componentData$.pipe(
    map((data) => data.tiles?.trim().split(' ')),
    map((codes) => codes?.map((code) => this.cmsService.getComponentData(code)))
  );

  constructor(private componentData: CmsComponentData<SsabCMSBannerGridComponent>,
              private cmsService: CmsService) {
  }
}
