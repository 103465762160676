<!--Popover templates  -->
<ng-template #popContentOrderIDInfo>
  <div class="text-default row vertical-align-item">
    <span class="ssab-icon icon-system-info icon-inline col-2"></span>
    <span class="pl-0 text-opacity col-10">{{ 'ssab.order.history.infoSearch' | cxTranslate }}</span>
  </div>
</ng-template>

<!-- Template for days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
             let-selected="selected">
  <ssab-datepicker-day
    [today]="today"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="true"
    [date]="date"
  >
  </ssab-datepicker-day>
</ng-template>

<!-- SEARCH CRITERIA -->
<form [formGroup]="searchOrderHistoryForm" (ngSubmit)="search()" class="cx-order-history-form d-none d-lg-block">
  <ng-container *ngIf="user$ | async as user">
    <div #orderSearch class="cx-search-form row" [ngClass]="user.internalUser ? 'is-internal-user' : ''">
      <h3 class="col-12 pb-5 d-lg-none d-md-block">{{'ssab.search.criteria' | cxTranslate}}
        <i class="ssab-icon icon-navigation-close icon-cursor float-right" (click)="hideFilterOptions($event)"></i>
      </h3>

      <div class="form-group cx-order-history-search-option col-lg-3 col-12">
        <label class="text-lg">
          <span>{{'ssab.order.history.reference.label' | cxTranslate}}</span>
          <span class="ssab-icon icon-inline icon-system-info d-none d-sm-inline-block ml-2 icon-white" role="button"
                placement="right"
                popoverClass="popover-sm popover-info" [ngbPopover]="popContentOrderIDInfo"
                triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
        </label>
        <ssab-cx-input class="pr-0 input-no-border"
                       [typeInput]="'text'"
                       [idName]="'orderReference'"
                       [nameForm]="'orderReference'"
                       [parentForm]="searchOrderHistoryForm"
                       [isNotValidFunction]="isInvalid('orderReference')"
                       [labelPlaceholder]="'ssab.order.history.reference.placeholder'"
                       (afterEnter)="afterEnter($event)">

        </ssab-cx-input>
      </div>

      <div #divStatus class="form-group cx-order-history-search-option cx-order-history-search-select col-lg-3 col-12">
        <h4 class="option-header">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3" (click)="hideSearchOptions(divStatus)"></i>
          {{'ssab.order.history.' + selectedView + '.status.label' | cxTranslate}}
          <i class="float-right ssab-icon icon-navigation-close icon-inline" (click)="hideSearchOptions(divStatus)"></i>
        </h4>
        <label class="text-lg">{{'ssab.order.history.' + selectedView + '.status.label' | cxTranslate}}
          <span class="ssab-icon icon-inline"></span>
        </label>
        <ng-select #status
                   [items]="orders?.allowedStatuses"
                   [searchable]="true"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   [clearable]="true"
                   (click)="toggleFilter(divStatus)"
                   (change)="hideSearchOptions(divStatus)"
                   dropdownPosition="bottom"
                   id="status"
                   formControlName="status"
                   placeholder="{{ 'ssab.order.history.'+selectedView+'.status.selectAll' | cxTranslate }}">
          <ng-template ng-option-tmp let-item="item">
            <div class="form-check mb-0 d-inline">
              <input class="form-check-input position-relative" type="checkbox">
            </div>
            <span>{{ "ssab.order.history.status." + item |cxTranslate}}</span>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <span>{{ "ssab.order.history.status." + item |cxTranslate}}</span>
          </ng-template>
          <ng-template ng-footer-tmp>
            <p class="w-100 mb-0"
               (click)="resetSelect(status)">{{ 'ssab.order.history.' + selectedView + '.status.selectAll' | cxTranslate }}</p>
          </ng-template>
        </ng-select>
      </div>

      <ng-container *ngIf="isOrderTrackingPage(); else OrderItemDates">
        <div #divCreationDateStart class="form-group cx-order-history-search-option col-lg-3 col-12">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.dateFrom' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'creationDateStart'"
                                 [idName]="'creationDateStart'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('creationDateStart')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.dateFrom'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('creationDateStart').value)"
                                 (click)="toggleFilter(divCreationDateStart)"
                                 (onChangeEvent)="hideSearchOptions(divCreationDateStart)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>

        <div #divCreationDateEnd class="form-group cx-order-history-search-option col-lg-3 col-12">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.dateTo' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'creationDateEnd'"
                                 [idName]="'creationDateEnd'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('creationDateEnd')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.dateTo'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('creationDateEnd').value)"
                                 (click)="toggleFilter(divCreationDateEnd)"
                                 (onChangeEvent)="hideSearchOptions(divCreationDateEnd)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>
      </ng-container>

      <ng-template #OrderItemDates>
        <div #divConfirmedWeekStart class="form-group cx-order-history-search-option col-lg-3 col-12">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.confirmedDate.from' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'confirmedWeekDateStart'"
                                 [idName]="'confirmedWeekDateStart'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('confirmedWeekDateStart')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.confirmedDate.from'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('confirmedWeekDateStart').value)"
                                 (click)="toggleFilter(divConfirmedWeekStart)"
                                 (onChangeEvent)="hideSearchOptions(divConfirmedWeekStart)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>

        <div #divConfirmedWeekEnd class="form-group cx-order-history-search-option col-lg-3 col-12">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.confirmedDate.to' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'confirmedWeekDateEnd'"
                                 [idName]="'confirmedWeekDateEnd'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('confirmedWeekDateEnd')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.confirmedDate.to'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('confirmedWeekDateEnd').value)"
                                 (click)="toggleFilter(divConfirmedWeekEnd)"
                                 (onChangeEvent)="hideSearchOptions(divConfirmedWeekEnd)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>

        <div #divEstimatedWeekStart class="form-group cx-order-history-search-option cx-option-hide col-lg-3 col-12"
             [ngClass]="{'d-lg-none': showHideFilter}">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.estimatedDate.from' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'estimatedWeekDateStart'"
                                 [idName]="'estimatedWeekDateStart'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('estimatedWeekDateStart')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.estimatedDate.from'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('estimatedWeekDateStart').value)"
                                 (click)="toggleFilter(divEstimatedWeekStart)"
                                 (onChangeEvent)="hideSearchOptions(divEstimatedWeekStart)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>

        <div #divEstimatedWeekEnd class="form-group cx-order-history-search-option cx-option-hide col-lg-3 col-12"
             [ngClass]="{'d-lg-none': showHideFilter}">
          <label class="text-lg d-none d-lg-block">{{'ssab.order.history.estimatedDate.to' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'estimatedWeekDateEnd'"
                                 [idName]="'estimatedWeekDateEnd'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchOrderHistoryForm"
                                 [isNotValidFunction]="isInvalid('estimatedWeekDateEnd')"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.order.history.estimatedDate.to'"
                                 [selectedDate]="convertDate(searchOrderHistoryForm.get('estimatedWeekDateEnd').value)"
                                 (click)="toggleFilter(divEstimatedWeekEnd)"
                                 (onChangeEvent)="hideSearchOptions(divEstimatedWeekEnd)"
                                 [navigation]="calendarNavigationTypes.Select"
          >
          </ssab-datepicker-input>
        </div>

        <div #divLateOrder
             class="form-group cx-order-history-search-option cx-option-hide cx-order-history-search-select col-lg-3 col-12"
             [ngClass]="{'d-lg-none': showHideFilter}">
          <h4 class="option-header">
            <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
               (click)="hideSearchOptions(divLateOrder)"></i>
            {{'ssab.order.history.grade.label' | cxTranslate}}
            <i class="float-right ssab-icon icon-navigation-close icon-inline"
               (click)="hideSearchOptions(divLateOrder)"></i>
          </h4>
          <label class="text-lg">{{'ssab.order.history.lateOrderNotification.label' | cxTranslate}}
            <span class="ssab-icon icon-inline"></span>
          </label>
          <ng-select #lateOrderNotification
                     [items]="lateOrderNotifications"
                     [searchable]="false"
                     [multiple]="false"
                     [closeOnSelect]="true"
                     [clearable]="true"
                     (click)="toggleFilter(divLateOrder)"
                     (change)="hideSearchOptions(divLateOrder)"
                     dropdownPosition="bottom"
                     id="lateOrderNotification"
                     formControlName="lateOrderNotification"
                     placeholder="{{ 'ssab.order.history.grade.selectAll' | cxTranslate }}">
            <ng-template ng-option-tmp let-item="item">
              {{('ssab.order.history.lateOrderNotification.' + item)|cxTranslate}}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              {{('ssab.order.history.lateOrderNotification.' + item)|cxTranslate}}
            </ng-template>
            <ng-template ng-footer-tmp>
              <p class="w-100 mb-0"
                 (click)="resetSelect(lateOrderNotification)">{{ 'ssab.order.history.grade.selectAll' | cxTranslate }}</p>
            </ng-template>
          </ng-select>
        </div>

      </ng-template>

      <ng-container *ngIf="user.internalUser">
        <div #divCompany
             class="form-group col-lg-3 col-12 cx-order-history-search-option cx-option-hide cx-order-history-search-select"
             [ngClass]="{'d-lg-none': showHideFilter}">
          <h4 class="option-header">
            <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
               (click)="hideSearchOptions(divCompany)"></i>
            {{'ssab.order.history.company.label' | cxTranslate}}
            <i class="float-right ssab-icon icon-navigation-close icon-inline"
               (click)="hideSearchOptions(divCompany)"></i>
          </h4>
          <label class="text-lg">{{'ssab.order.history.company.label' | cxTranslate}}
            <span class="ssab-icon icon-inline"></span>
          </label>
          <ng-select #company
                     [items]="companies$ | async"
                     [searchable]="true"
                     [closeOnSelect]="true"
                     [clearable]="true"
                     (click)="toggleFilter(divCompany)"
                     (clear)="clearCompanies()"
                     (change)="onCompanyChange()"
                     [typeahead]="companiesInput$"
                     dropdownPosition="bottom"
                     bindLabel="name"
                     bindValue="uid"
                     id="company"
                     formControlName="company"
                     placeholder="{{ 'ssab.order.history.company.selectAll' | cxTranslate }}"
          >
            <ng-template ng-option-tmp let-item="item">
              <span title="{{item.name}}">{{item.name}}</span>
            </ng-template>
            <ng-template ng-footer-tmp>
              <p class="w-100 mb-0"
                 (click)="resetCompanySelect(company)">{{ 'ssab.order.history.company.selectAll' | cxTranslate }}</p>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>
      <ng-container *ngIf="!user.internalUser">
        <div #divCustomers
             class="form-group col-lg-3 col-12 cx-order-history-search-option cx-option-hide cx-order-history-search-select"
             [ngClass]="{'d-lg-none': showHideFilter}"
             *ngIf="orders?.allowedCustomers!=null && orders?.allowedCustomers.length > 1">
          <h4 class="option-header">
            <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
               (click)="hideSearchOptions(divCustomers)"></i>
            {{'ssab.order.history.customer.label' | cxTranslate}}
            <i class="float-right ssab-icon icon-navigation-close icon-inline"
               (click)="hideSearchOptions(divCustomers)"></i>
          </h4>
          <label class="text-lg">{{'ssab.order.history.customer.label' | cxTranslate}}
            <span class="ssab-icon icon-inline"></span>
          </label>
          <ng-select #customers
                     [items]="customerIds$ | async"
                     [searchable]="true"
                     [closeOnSelect]="true"
                     [clearable]="true"
                     (click)="toggleFilter(divCustomers)"
                     (change)="hideSearchOptions(divCustomers)"
                     [typeahead]="customerIdsInput$"
                     dropdownPosition="bottom"
                     bindLabel="name"
                     bindValue="uid"
                     id="customer_internal"
                     formControlName="customers"
                     (clear)="clearCustomers()"
                     placeholder="{{ 'ssab.order.history.customer.selectAll' | cxTranslate }}"
          >
            <ng-template ng-option-tmp let-item="item">
              <span title="{{item.name}}">{{item.name}}</span>
            </ng-template>
            <ng-template ng-footer-tmp>
              <p class="w-100 mb-0"
                 (click)="resetSelect(customers)">{{ 'ssab.order.history.customer.selectAll' | cxTranslate }}</p>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>

      <div #divChannels
           class="form-group cx-order-history-search-option cx-option-hide cx-order-history-search-select col-lg-3 col-12"
           [ngClass]="{'d-lg-none': showHideFilter}">
        <h4 class="option-header">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3" (click)="hideSearchOptions(divChannels)"></i>
          {{'ssab.order.history.channel.label' | cxTranslate}}
          <i class="float-right ssab-icon icon-navigation-close icon-inline" (click)="hideSearchOptions(divChannels)"></i>
        </h4>
        <label class="text-lg">{{'ssab.order.history.channel.label' | cxTranslate}}
          <span class="ssab-icon icon-inline"></span>
        </label>
        <ng-select #channels
                   [items]="allowedSalesChannels"
                   [searchable]="true"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   [clearable]="true"
                   (click)="toggleFilter(divChannels)"
                   (change)="hideSearchOptions(divChannels)"
                   dropdownPosition="bottom"
                   id="salesChannel"
                   formControlName="salesChannel"
                   placeholder="{{ 'ssab.order.history.channel.selectAll' | cxTranslate }}">
          <ng-template ng-option-tmp let-item="item">
            <div class="form-check mb-0 d-inline">
              <input class="form-check-input position-relative" type="checkbox">
            </div>
            <span
              *ngIf="item.startsWith('salesChannel_')">{{ "ssab.order.history.channel." + (item.replace('salesChannel_', '') | lowercase) |cxTranslate |uppercase}}</span>
            <span *ngIf="!item.startsWith('salesChannel_')">{{ item.replace('plant_', '') |uppercase}}</span>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
          <span
            *ngIf="item.startsWith('salesChannel_')">{{ "ssab.order.history.channel." + (item.replace('salesChannel_', '') | lowercase) |cxTranslate |uppercase}}</span>
            <span *ngIf="!item.startsWith('salesChannel_')">{{ item.replace('plant_', '')|uppercase }}</span>
          </ng-template>
          <ng-template ng-footer-tmp>
            <p class="w-100 mb-0"
               (click)="resetSelect(channels)">{{ 'ssab.order.history.channel.selectAll' | cxTranslate }}</p>
          </ng-template>
        </ng-select>
      </div>

      <div #divGrades
           class="form-group cx-order-history-search-option cx-option-hide cx-order-history-search-select col-lg-3 col-12"
           [ngClass]="{'d-lg-none': showHideFilter}">
        <h4 class="option-header">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3" (click)="hideSearchOptions(divGrades)"></i>
          {{'ssab.order.history.grade.label' | cxTranslate}}
          <i class="float-right ssab-icon icon-navigation-close icon-inline" (click)="hideSearchOptions(divGrades)"></i>
        </h4>
        <label class="text-lg">{{'ssab.order.history.grade.label' | cxTranslate}}
          <span class="ssab-icon icon-inline"></span>
        </label>
        <ng-select #grades
                   [items]="orders?.allowedGrades"
                   [searchable]="true"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   [clearable]="true"
                   (click)="toggleFilter(divGrades)"
                   (change)="hideSearchOptions(divGrades)"
                   dropdownPosition="bottom"
                   id="grade"
                   formControlName="grade"
                   placeholder="{{ 'ssab.order.history.grade.selectAll' | cxTranslate }}">
          <ng-template ng-option-tmp let-item="item">
            <div class="form-check mb-0 d-inline">
              <input class="form-check-input position-relative" type="checkbox">
            </div>
            <span>{{ item }}</span>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item }}</span>
          </ng-template>
          <ng-template ng-footer-tmp>
            <p class="w-100 mb-0"
               (click)="resetSelect(grades)">{{ 'ssab.order.history.grade.selectAll' | cxTranslate }}</p>
          </ng-template>
        </ng-select>
      </div>

      <div #divThickness class="form-group cx-order-history-search-option cx-option-hide col-lg-3 col-12"
           [ngClass]="{'d-lg-none': showHideFilter}">
        <h4 class="option-header">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
             (click)="hideSearchOptions(divThickness,inputRangeThickness)"></i>
          {{'ssab.selectRange' | cxTranslate}}
          <i class="float-right ssab-icon icon-navigation-close icon-inline"
             (click)="hideSearchOptions(divThickness, inputRangeThickness)"></i>
        </h4>
        <label class="text-lg">{{'ssab.order.history.thickness.label' | cxTranslate}}
          <span class="ssab-icon icon-inline"></span></label>
        <div class="value facet-range vertical-align-item">
          <ssab-cx-input-range #inputRangeThickness
                               [inputValueFrom]="orders?.thicknessMin"
                               [inputValueTo]="orders?.thicknessMax"
                               [inputValueStep]="0.01"
                               [sliderForm]="searchOrderHistoryForm"
                               class="w-100"
                               (inputRangeOpen)="toggleFilter(divThickness)"
                               (inputRangeClosed)="hideSearchOptions(divThickness, inputRangeThickness)"
          ></ssab-cx-input-range>
        </div>
      </div>

      <ng-container *ngIf="user.internalUser">
        <div #divCustomers
             class="form-group col-lg-3 col-12 cx-order-history-search-option cx-option-hide cx-order-history-search-select"
             [ngClass]="{'d-lg-none': showHideFilter}"
             *ngIf="orders?.allowedCustomers!=null">
          <h4 class="option-header">
            <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
               (click)="hideSearchOptions(divCustomers)"></i>
            {{'ssab.order.history.customer.label' | cxTranslate}}
            <i class="float-right ssab-icon icon-navigation-close icon-inline"
               (click)="hideSearchOptions(divCustomers)"></i>
          </h4>
          <label class="text-lg">{{'ssab.order.history.customer.label' | cxTranslate}}
            <span class="ssab-icon icon-inline"></span>
          </label>
          <ng-select #customers
                     [items]="customerIds$ | async"
                     [searchable]="true"
                     [closeOnSelect]="true"
                     [clearable]="true"
                     (click)="toggleFilter(divCustomers)"
                     (change)="onCustomerChange(divCustomers)"
                     [typeahead]="customerIdsInput$"
                     dropdownPosition="bottom"
                     bindLabel="name"
                     bindValue="uid"
                     id="customer_external"
                     formControlName="customers"
                     (clear)="clearCustomers()"
                     placeholder="{{ 'ssab.order.history.customer.selectAll' | cxTranslate }}"
          >
            <ng-template ng-option-tmp let-item="item">
              <span title="{{item.name}}">{{item.name}}</span>
            </ng-template>
            <ng-template ng-footer-tmp>
              <p class="w-100 mb-0"
                 (click)="resetCustomersSelect(customers)">{{ 'ssab.order.history.customer.selectAll' | cxTranslate }}</p>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>

      <div #divShipToLocation
           class="form-group col-lg-3 col-12 cx-order-history-search-option cx-option-hide cx-order-history-search-select"
           [ngClass]="{'d-lg-none': showHideFilter}">
        <h4 class="option-header">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
             (click)="hideSearchOptions(divShipToLocation)"></i>
          {{'ssab.order.history.shipToLocation.label' | cxTranslate}}
          <i class="float-right ssab-icon icon-navigation-close icon-inline"
             (click)="hideSearchOptions(divShipToLocation)"></i>
        </h4>
        <label class="text-lg">{{'ssab.order.history.shipToLocation.label' | cxTranslate}}
          <span class="ssab-icon icon-inline"></span>
        </label>
        <ng-select #shipToLocation
                   [items]="shipToLocations$ | async"
                   [searchable]="true"
                   [closeOnSelect]="true"
                   [clearable]="true"
                   (click)="toggleFilter(divShipToLocation)"
                   (change)="hideSearchOptions(divShipToLocation)"
                   [typeahead]="shipToLocationsInput$"
                   dropdownPosition="bottom"
                   bindLabel="name"
                   bindValue="uid"
                   id="shipToLocation"
                   formControlName="shipToLocation"
                   (clear)="clearShipTo()"
                   placeholder="{{ 'ssab.order.history.shipToLocation.selectAll' | cxTranslate }}"
        >
          <ng-template ng-option-tmp let-item="item">
            <span title="{{item.name}}">{{item.name}}</span>
          </ng-template>
          <ng-template ng-footer-tmp>
            <p class="w-100 mb-0"
               (click)="resetShipToLocationSelect(shipToLocation)">{{ 'ssab.order.history.shipToLocation.selectAll' | cxTranslate }}</p>
          </ng-template>
        </ng-select>
      </div>

      <!!--This is for making a break in the columns so buttons can be separated -->
      <div class="w-100 pb-3"></div>

      <div
        class="col-auto col-md-6 col-lg-6 text-white d-lg-block d-md-none text-bold justify-content-start d-flex align-self-center text-lg">
        <span (click)="showHideFilterOptions()" class="cursor-pointer">
          {{ 'ssab.order.history.filter.' + showHideFilter | cxTranslate }}
          <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-white"></i>
        </span>
      </div>

      <div class="col-12 col-md-3 text-right form-group ml-md-auto d-md-block d-none">
        <button
          (click)="reset()"
          class="btn btn-link btn-default-height text-uppercase text-right p-0"
          type='button'>
          {{'ssab.search.reset' | cxTranslate}}
        </button>
      </div>

      <div class="col-12 col-md-3 form-group">
        <button
          class="btn btn-primary btn-default-height btn-block"
          type="submit">
          {{ 'ssab.search.resultTitle' | cxTranslate }}
        </button>
      </div>

      <div class="col-12 col-md-3 text-center form-group d-md-none d-block">
        <button
          (click)="reset()"
          class="btn btn-link btn-reset btn-default-height text-uppercase text-right p-0"
          type='button'>
          {{'ssab.search.reset' | cxTranslate}}
        </button>
      </div>

    </div>
  </ng-container>
</form>
