import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SsabUserService} from "../../service/user/ssab-user.service";
import {SsabCertificateDocument} from "../../model/document.model";
import {map, tap} from "rxjs/operators";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
})
export class ScannerProductsComponent implements OnInit {
  searchQuery: string = '';
  productDataList: Observable<SsabCertificateDocument[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: SsabUserService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      const materialId = params['search'];
      this.searchQuery = materialId;
      this.fetchData(materialId);
    });
  }

  fetchData(materialId: string): void {
    this.productDataList = this.userService.searchByMaterialId(materialId)
      .pipe(
        map(d => d.documents),
        tap(d => {
          if (d.length === 1) {
            window.history.replaceState('', document.title, '/scanner');
            this.handleProductChoose(d[0], true);// if only one hit, redirect to product card
          }
        })
      );
  }

  async handleInputValueChange(newValue: string) {
    sessionStorage.clear();
    this.fetchData(newValue);
    this.router.navigate([], {
      queryParams: {search: newValue},
    });
  }

  handleProductChoose(product: SsabCertificateDocument, noBackLink: boolean) {
    if (product) {
      this.router.navigate(['scanner/product'], {queryParams: {certificateId: product.certificateId, searchTerm: this.searchQuery, noBackLink}});
    }
  }

  onScanMoreProducts() {
    this.router.navigate(['/scanner']);
  }
}
