import {Component} from '@angular/core';
import {UpdateProfileComponent, UpdateProfileComponentService} from '@spartacus/user/profile/components';
import {Observable} from 'rxjs';
import {UserService} from '@spartacus/core';
import {SsabUser} from '../../../model/user.model';
import {Router} from '@angular/router';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";
import {Actions} from '@ngrx/effects';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {SsabGlobalConfiguration} from "../../../model/misc.model";

@Component({
  selector: 'ssab-cx-update-profile',
  templateUrl: './ssab-my-details.component.html',
  providers: [UserService]
})
export class SsabMyDetailsComponent extends UpdateProfileComponent {
  user$: Observable<SsabUser>;
  loading$: Observable<boolean>;
  configuration$: Observable<SsabGlobalConfiguration>;

  constructor(
    protected activeCartService: SsabActiveCartService,
    protected updateProfileComponentService: UpdateProfileComponentService,
    protected userService: SsabUserService,
    protected router: Router,
    private globalConfiguration: SsabGlobalconfigurationService,
    protected actions$: Actions
  ) {
    super(updateProfileComponentService);
    this.user$ = this.userService.getUserDetails();
    this.configuration$ = this.globalConfiguration.getGlobalConfiguration();
  }

}
