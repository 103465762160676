<div class="table-responsive">
  <table class="table cx-previous-orders-table">
    <thead table-head
           (sortChange)="sort($event)"
           [activeSort]="activeSort"
           [label]="'ssab.order.history.'"
           [tableHeadType]="tableTypes.PreviousOrders">
    </thead>
    <tbody>
    <ng-container *ngFor="let order of orders">
      <tr #orderTr (click)="toggleEntries($event,orderIcon,orderTable,orderTr)">
        <td class="col-md-5" (click)="toggleEntries($event,orderIcon,orderTable,orderTr)">
          <i class="ssab-icon icon-chevron-down icon-inline icon-cursor"
             (click)="toggleEntries($event, orderIcon, orderTable, orderTr)"
             #orderIcon></i>
          <span class="pl-4">{{ order?.code }}</span>
        </td>
        <td class="col-md-2">
          {{ order?.purchaseOrderNumber  }}
        </td>
        <td class="col-md-5">
          <span *ngIf="order?.created">{{ order?.created | cxDate: 'longDate' }}</span>
        </td>
      </tr>
      <tr class="d-none" #orderTable>
        <td class="previous-orders-table" colspan="6">
          <div class="table-entries">
            <div class="w-100 d-md-flex pb-4">
              <p
                class="text-bold text-uppercase d-md-inline align-self-center mb-md-0">
                {{'ssab.previous.orders.orderedItems' | cxTranslate}}
              </p>
              <button
                *ngIf="reOrderEnabled(order)"
                (click)="repeatOrder(order)"
                class="btn btn-primary btn-default-height ml-md-auto ">
                {{'ssab.previous.orders.repeatOrder' | cxTranslate}}
              </button>
            </div>
            <ng-container *ngFor="let entry of order.entries">
              <ssab-cx-previous-orders-entry [entry]="entry"></ssab-cx-previous-orders-entry>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
