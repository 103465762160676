import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {SsabOrderHistory, SsabOrderHistoryRequest} from '../../../model/order.model';
import {Sort, TablesTypes} from '../../../model/misc.model';
import {catchError, filter, tap} from 'rxjs/operators';
import {openCloseSpinner} from '../../shared/utils/functions/ssab-functions-utils';
import {UntypedFormBuilder} from '@angular/forms';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ssab-cx-previous-orders',
  templateUrl: './ssab-previous-orders.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabPreviousOrdersComponent implements OnInit, OnDestroy {

  orders$: Observable<SsabOrderHistory>;
  activeSort$ = new BehaviorSubject<Sort>({field: 'createdAt', descending: true});
  defaultPageSize = 25;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected userService: SsabUserService,
    protected activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.pageSize) {
          this.defaultPageSize = +params.pageSize;
        }
      })
    );
    this.search();
  }

  pageChange(from: number): void {
    this.search(from);
  }

  sort(sort: Sort): void {
    this.activeSort$.next(sort);
    this.search();
  }

  search(from = 0): void {
    openCloseSpinner(true);
    this.orders$ = this.userService.getOrderHistory(TablesTypes.PreviousOrders, this.getOrderHistoryRequest(from))
      .pipe(
        tap(() => {
          openCloseSpinner(false);
        }),
        filter(Boolean),
        catchError(() => {
          openCloseSpinner(false);
          return of({});
        }), // empty on error
      );
  }

  getOrderHistoryRequest(from = 0): SsabOrderHistoryRequest {
    return {
      sorts: [this.activeSort$.getValue(),{field: 'orderId', descending: true}],
      size: this.defaultPageSize,
      from,
      buyerIds: [],
    } as SsabOrderHistoryRequest;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

