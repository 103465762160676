<ng-container *ngIf="isAzureLoginInProgressAndNoASM()">
  <div class="text-center text-primary pb-5">
    {{ 'ssab.azure.login.inprogress' | cxTranslate }}
  </div>
  <ssab-process-azure-login></ssab-process-azure-login>
</ng-container>
<div [ngClass]="{'hidden':(isAllLoginMethodsLoaded()|async) }">
  <cx-spinner></cx-spinner>
</div>
<div *ngIf="!isAzureLoginInProgress()" [ngClass]="{'hidden':!(isAllLoginMethodsLoaded()|async) }">
  <div class="text-primary">
    <h2 class="text-left mb-4">{{'loginForm.eCom.label' | cxTranslate}}</h2>
    <p>{{'loginForm.eCom.description' | cxTranslate}}</p>
  </div>

  <ng-container *ngIf="!(isResetPasswordPage() | async) ; else resetPassword">
    <!-- if regular login page -->
    <div [id]="getCdcLoginContainerId()"></div>
    <ssab-cdc-login *ngIf="isCdcLoginActive() | async"></ssab-cdc-login>

    <ssab-sso-login *ngIf="isSSOLoginActive() | async"></ssab-sso-login>
  </ng-container>
  <ng-template #resetPassword>
    <!-- if reset password page -->
    <ssab-cdc-password-reset></ssab-cdc-password-reset>
  </ng-template>

  <a class="text-primary float-right pb-3" target="_blank" rel="noopener noreferrer"
     href="{{ 'loginForm.ssab.link.account.request' | cxTranslate }}">{{ 'loginForm.ssab.account.request' | cxTranslate }}</a>

  <div class="text-center text-primary pt-3" *ngIf="isAzureLoginActiveAndNoASM() | async">
    <ssab-start-azure-login (startLogin)="startAzureLogin()" id="ssoLoginForm"
                            buttonId="ssoLoginButton"></ssab-start-azure-login>
    <ssab-process-azure-login></ssab-process-azure-login>
  </div>

  <div class="text-center text-primary pt-5" *ngIf="isCommerceLoginActive() | async">
    <form (ngSubmit)="checkSubmitForm()" [formGroup]="form" name="ecomLoginForm" id="ecomLoginForm">
      <div class="form-group input-group-icon text-left">
        <span class="ssab-icon icon-system-profile"></span>
        <input
          type="email"
          class="form-control pl-5"
          formControlName="userId"
          id="userId"
          name="userId"
          placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}"
          autocomplete="username"
        />
        <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
      </div>
      <div class="form-group input-group-icon text-left">
        <span class="ssab-icon icon-navigation-unlocked"></span>
        <input
          type="password"
          class="form-control pl-5"
          id="password"
          name="password"
          placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}"
          formControlName="password"
          autocomplete="current-password"
        />
        <cx-form-errors [control]="form.get('password')"></cx-form-errors>
      </div>
      <!--    <p class="text-right mb-5 text-uppercase">-->
      <!--      <a-->
      <!--        [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"-->
      <!--        aria-controls="reset-password"-->
      <!--        class="btn-link"-->
      <!--      >{{ 'loginForm.forgotPassword' | cxTranslate }}</a-->
      <!--      >-->
      <!--    </p>-->

      <p class="text-danger d-none" id="login-error" *ngIf="form.invalid">
        {{ 'loginForm.loginError' | cxTranslate }}
      </p>
      <button type="submit" name="ecomLoginButton" id="ecomLoginButton" class="btn btn-block btn-primary btn-lg">
        {{ 'loginForm.signIn' | cxTranslate }}
      </button>
    </form>
  </div>
</div>
