import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import { filter, map,} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SsabProduct} from '../../../model/product.model';
import {Product} from '@spartacus/core';


@Component({
  selector: 'ssab-cx-product-images',
  templateUrl: './ssab-product-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductImagesComponent implements OnInit{
  private mainMediaContainer = new BehaviorSubject(null);

  @Input() product: SsabProduct;

  mainImage$ ;

  thumbs$: Observable<any[]>;

  ngOnInit(): void {
    this.mainMediaContainer.next(this.product.images?.PRIMARY ?  this.product.images.PRIMARY : {});

    this.mainImage$ = combineLatest([of(this.product), this.mainMediaContainer]).pipe(
      map(([, container]) => container)
    );

    this.createThumbs(this.product);
  }

  openImage(item: any): void {
    this.mainMediaContainer.next(item);
  }

  isActive(thumbnail): Observable<boolean> {
    return this.mainMediaContainer.pipe(
      filter(Boolean),
      map((container: any) => {
        return (
          container.zoom &&
          container.zoom.url &&
          thumbnail.zoom &&
          thumbnail.zoom.url &&
          container.zoom.url === thumbnail.zoom.url
        );
      })
    );
  }

  /** find the index of the main media in the list of media */
  getActive(thumbs: any[]): Observable<number> {
    return this.mainMediaContainer.pipe(
      filter(Boolean),
      map((container: any) => {
        const current = thumbs.find(
          (t) =>
            t.media &&
            container.zoom &&
            t.media.container &&
            t.media.container.zoom &&
            t.media.container.zoom.url === container.zoom.url
        );
        return thumbs.indexOf(current);
      })
    );
  }

  /**
   * Return an array of CarouselItems for the product thumbnails.
   * In case there are less then 2 thumbs, we return null.
   */
  private createThumbs(product: Product): void
  {
    if (
      !product.images ||
      !product.images.GALLERY ||
      product.images.GALLERY.length < 2
    ) {
      this.thumbs$ = of([]);
    }

    this.thumbs$ = product.images?.GALLERY ? of((< any[]> product.images?.GALLERY).map((c) => of({ container: c }))) : of([]);
  }
}
