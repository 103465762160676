import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SsabProduct, SsabUnitOfMeasure} from '../../../model/product.model';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'ssab-unit-picker-cart',
  templateUrl: './ssab-unit-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabUnitPickerComponent implements OnInit {
  @Input() product: SsabProduct;
  @Output() update = new EventEmitter<SsabUnitOfMeasure>();
  @Input() readonly: boolean = false
  @Input() cssClassMulti: string = '';
  @Input() cssClassSingle: string = '';
  @Input() parentForm: FormGroup;
  @Input() nameForm: string;
  @Input() selectedUnit: string;
  @Input() appendTo = 'body'

  units: CodeName[];

  constructor() {
  }

  ngOnInit(): void {
    this.units = this.product.productUnitConversions?.map(u => ({
        code: u.unit.code,
        name: u.unit.name
      } as CodeName)
    );
    this.showUnitOfMeasures();
  }

  unitChange(updatedUnit: SsabUnitOfMeasure): void {
    this.update.emit(updatedUnit);
  }


  //TODO this is not correct place, needs to go to backend
  showUnitOfMeasures(): void {
    if (this.units?.length <= 0 || !this.units) {
      // sheets and plates should show kg and pcs for contact sales
      if (this.product.productForm === ('Sheet') || this.product.productForm === ('Plate')) {
        this.units = [{
          code: 'EA',
          name: 'pcs'
        }, {
          code: this.product.unitOfWeight?.code,
          name: this.product.unitOfWeight?.name
        }];
        this.selectedUnit = 'EA';
      } else {
        this.units = [{
          code: this.product.unitOfWeight?.code,
          name: this.product.unitOfWeight?.name
        }];
        this.selectedUnit = this.product.unitOfWeight?.code;
      }
    }
  }
}

export interface CodeName {
  code: string;
  name: string;
}
