import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Configuration, PublicClientApplication} from '@azure/msal-browser';
import {MSAL_INSTANCE, PROCESS_LOGIN_PATH} from './model/msal.constants';
import {SsabUrlService} from '../url/ssab-url.service';
import {SsabProcessAzureLoginComponent} from './components/ssab-process-azure-login.component';
import {AzureAuthenticationRoutingModule} from './routing/azure-authentication-routing.module';
import {BaseSiteService, I18nModule} from '@spartacus/core';
import {from, Observable} from 'rxjs';
import {SsabBaseSite} from '../../model/misc.model';
import {mergeMap} from 'rxjs/operators';
import {MsalService} from './services/msal.service';
import {SsabStartAzureLoginComponent} from './components/start-azure-login/ssab-start-azure-login.component';
import {FormsModule} from "@angular/forms";
import {SsabCdcLoginComponent} from './components/cdc-login/ssab-cdc-login.component';
import {SsabCdcPasswordResetComponent} from "./components/password-reset/ssab-cdc-password-reset.component";
import {SsabSsoLoginComponent} from "./components/sso-login/ssab-sso-login.component";


function MSALInstanceFactory(urlService: SsabUrlService, baseSiteService: BaseSiteService): Observable<PublicClientApplication> {
  const redirectUrl = urlService.getBaseUrlWithContextParams() + '/' + PROCESS_LOGIN_PATH;

  return baseSiteService.get()
    .pipe(
      mergeMap((baseSite: SsabBaseSite) => {
        console.log('Redirect URL: ' + redirectUrl);
        const ctrl = new PublicClientApplication({
          auth: {
            authority: baseSite.azureAuthority,
            clientId: baseSite.azureClientId,
            redirectUri: redirectUrl
          }
        } as Configuration);
        return from(ctrl.initialize().then(() => {
          console.log('MSAL initialized');
          return ctrl;
        }));
      })
    );
}

@NgModule({
  declarations: [SsabProcessAzureLoginComponent, SsabStartAzureLoginComponent, SsabCdcLoginComponent, SsabCdcPasswordResetComponent, SsabSsoLoginComponent,],
  imports: [
    CommonModule,
    FormsModule,
    AzureAuthenticationRoutingModule,
    I18nModule
  ],
  exports: [
    SsabProcessAzureLoginComponent,
    SsabStartAzureLoginComponent,
    SsabCdcLoginComponent,
    SsabCdcPasswordResetComponent,
    SsabSsoLoginComponent
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [SsabUrlService, BaseSiteService]
    },
    MsalService
  ],
  entryComponents: [SsabProcessAzureLoginComponent,]
})

export class SsabAzureAuthenticationModule {
}
