import {Component, OnInit} from '@angular/core';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {AuthService, BaseSiteService, LanguageService, RoutingService} from '@spartacus/core';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SsabUser} from '../../../model/user.model';
import {SsabAuthRedirectService} from "../../../service/user-auth/ssab-auth-redirect.service";
import {openCloseSpinner} from "../../shared/utils/functions/ssab-functions-utils";

@Component({
  selector: 'ssab-cx-store-selector',
  templateUrl: './ssab-store-selector.component.html',
})

export class SsabStoreSelectorComponent implements OnInit {
  stores$: Observable<string []>;


  constructor(
    protected userService: SsabUserService,
    protected baseSiteService: BaseSiteService,
    protected languageService: LanguageService,
    protected routing: RoutingService,
    protected auth: AuthService,
    protected authRedirectService: SsabAuthRedirectService,
  ) {
    this.stores$ = this.userService.get().pipe(
      tap(
        (userDetails: SsabUser) => {
          if (!(userDetails?.storeAccesses?.length > 0)) {// no access, logout
            this.auth.coreLogout();
          }
        }
      ),
      map((userDetails: SsabUser) =>
        userDetails?.storeAccesses ?? []
      ));
  }

  ngOnInit(): void {
    openCloseSpinner(false);
  }

  navigate(baseSiteId: string): void {
    this.authRedirectService.handleStoreSelection(baseSiteId, true, true);
  }

}
