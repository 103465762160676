<div class="cx-page-header-title text-white">
  <h2 class="pb-3"> {{'ssab.store.selector.title' | cxTranslate }} </h2>
  <p> {{'ssab.store.selector.description' | cxTranslate}} </p>
</div>


<div class="pt-3 pb-5 cx-stores">
  <section class="mx-auto">
    <ul class="pt-5 pb-3" *ngIf="stores$ | async as stores">
      <li class="text-underline-blue {{store != 'gcp' ? '': 'pt-4'}}" *ngFor="let store of stores;"
          (click)="navigate(store)">
        {{'ssab.store.selector.store.' + store.toLocaleLowerCase() | cxTranslate  }}
    </ul>
  </section>
</div>

