import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {provideDefaultConfig, UserOccModule, UserService} from '@spartacus/core';
import {OrderHistoryAdapter} from '@spartacus/order/core';
import {OrderHistoryConnector} from '@spartacus/order/core';
import {SsabUserService} from './ssab-user.service';
import {SsabOccUserOrderAdapter} from './ssab-occ-user-order.adapter';
import {SsabUserOrderConnector} from './ssab-user-order.connector';
import {ssabOccUserConfig} from './ssab-occ-user-config';
import {SsabOccUserProfileAdapter} from './ssab-occ-user-profile.adapter';
import {SsabUserProfileConnector} from './ssab-user-profile.connector';
import {UserProfileAdapter, UserProfileConnector} from '@spartacus/user/profile/core';
import {EffectsModule} from '@ngrx/effects';
import {SsabUserEffects} from './ssab-user.effect';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([SsabUserEffects])
  ],
  providers: [
    provideDefaultConfig(ssabOccUserConfig),
    {
      provide: UserService,
      useClass: SsabUserService
    },
    {
      provide: OrderHistoryAdapter,
      useClass: SsabOccUserOrderAdapter
    },
    {
      provide: UserProfileAdapter,
      useClass: SsabOccUserProfileAdapter
    },
    {
      provide: OrderHistoryConnector,
      useClass: SsabUserOrderConnector
    },
    {
      provide: UserProfileConnector,
      useClass: SsabUserProfileConnector
    }
  ],
})
export class SsabUserOccModule extends UserOccModule {
}
