import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from '@angular/core';
import {ButtonAction, ProductUnitConversion, SsabPrice, SsabProduct, SsabStock, StepperUpdateData} from '../../../model/product.model';
import {SsabProductService} from '../../../service/product/ssab-product.service';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseSiteService} from '@spartacus/core';
import {LaunchDialogService} from '@spartacus/storefront';

@Component({
  selector: 'ssab-cx-product-price',
  templateUrl: './ssab-product-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductPriceComponent implements OnInit, OnChanges {
  currentUnit: ProductUnitConversion;
  selectedUnit: ProductUnitConversion;
  currentStock: SsabStock;
  currentQuantity = 1;
  currentPrice: SsabPrice;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;
  showConsignmentPricesStock: boolean;
  currentPriceChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  baseSite$: Observable<string>;

  @Input() isPLPView: boolean = false;
  @Input() product: SsabProduct;
  @Output() update = new EventEmitter<ProductUnitConversion>();

  constructor(protected cdr: ChangeDetectorRef,
              protected productService: SsabProductService,
              protected userService: SsabUserService,
              protected launchDialogService: LaunchDialogService,
              protected baseSiteService: BaseSiteService) {
    this.baseSite$ = this.baseSiteService.getActive();
  }

  ngOnInit(): void {
    this.determineUnitConversion(this.product.unit.code);
    this.currentUnit = this.selectedUnit;
    this.currentQuantity = this.product.stock.minimumAvailableQuantity;
    this.currentStock = this.product.stock;
    this.currentPrice = this.product.price;
    this.currentPriceChanged$.next(!this.isKgSelected());
    this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(!this.product.stock?.consignment);
    this.showConsignmentPricesStock = this.product.buttonAction === ButtonAction.consignmentStock;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  determineUnitConversion(unit: string): void {
    this.selectedUnit = this.product.productUnitConversions?.filter(pc => pc.unit.code === unit)[0];
  }

  notifyChange(): void {
    this.update.emit({...this.selectedUnit, quantity: this.currentQuantity});
    this.cdr.detectChanges();
  }

  selectUnit(unit: string): void {
    this.determineUnitConversion(unit);
    const priceSubscription = this.productService.getCatalogPrice(this.product, this.selectedUnit?.unit, this.currentQuantity).subscribe(
      p => {
        this.currentPrice = p.price;
        this.notifyChange();
        this.currentPriceChanged$.next(!this.isKgSelected());
        priceSubscription.unsubscribe();
      }
    );
    const availabilitySubscription = this.productService.getCatalogAvailability(this.product, this.selectedUnit?.unit).subscribe(
      stock => {
        this.currentStock = stock;
        this.currentQuantity = stock.minimumAvailableQuantity;
        this.notifyChange();
        availabilitySubscription.unsubscribe();
      }
    );
  }

  onChange(data: StepperUpdateData): void {
    if (data.qty) {
      this.currentQuantity = data.qty;
    }
    if (data.unit) {
      this.selectUnit(data.unit);
    }
    this.notifyChange();
  }

  isKgSelected(): boolean {
    return this.selectedUnit?.unit?.code === 'KG';
  }

  isUnitM2(): boolean {
    return this.selectedUnit?.unit?.code === 'M2';
  }
}
