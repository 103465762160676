import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild,} from '@angular/core';

import {BarcodeFormat, BrowserMultiFormatReader, DecodeHintType} from '@zxing/library';
import {SsabUserService} from "../../service/user/ssab-user.service";
import {SsabScannerDataValidationService} from "../../service/scanner/ssab-scanner-data-validation.service";
import {take} from "rxjs/operators";


@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
})
export class QrscannerComponent implements AfterViewInit {
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  codeReader: BrowserMultiFormatReader;
  ocrOn: boolean = false;
  scannerOn: boolean = false;
  isPortraitMode: boolean = false;

  constructor(protected cdr: ChangeDetectorRef,
              protected userService: SsabUserService,
              protected scannerDataValidationService: SsabScannerDataValidationService
  ) {
    const hints = new Map();
    const enabledFormats = [
      BarcodeFormat.CODE_39,
      BarcodeFormat.UPC_A,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.QR_CODE
    ];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, enabledFormats);
    this.codeReader = new BrowserMultiFormatReader(/*hints*/);
    this.checkOrientation();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkOrientation();
    this.cdr.detectChanges();
  }

  checkOrientation(): void {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

  ngAfterViewInit(): void {
    this.startVideo();
  }

  async startVideo() {
    this.codeReader.decodeFromVideoDevice(
      null,
      this.videoElement.nativeElement,
      () => null
    );
  }

  //activate scanner
  async scan() {
    this.scannerOn = true;
    this.codeReader.reset();
    if (this.videoElement.nativeElement.paused) {
      this.codeReader.decodeFromVideoDevice(
        null,
        this.videoElement.nativeElement,
        (result, err) => {
          if (result) {
            // @ts-ignore
            const serialNumber = this.scannerDataValidationService.validateSerialNumber(result.text);
            this.valueChange.emit(serialNumber);
          }
          if (err) {
            console.error(err);
          }
        }
      );
    }
  }

  //image => ocr
  async ocr() {
    this.ocrOn = true;
    this.scannerOn = false;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');

    if (context) {
      canvas.width = this.videoElement.nativeElement.videoWidth;
      canvas.height = this.videoElement.nativeElement.videoHeight;

      context.drawImage(
        this.videoElement.nativeElement,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob: any) => resolve(blob), 'image/png');
    });
    if (blob) {
      this.userService.searchByOcr(blob)
        .pipe(take(1))
        .subscribe(
        data => {
          this.valueChange.emit(this.scannerDataValidationService.validateOCR(data));
          this.ocrOn = false;
          this.cdr.detectChanges();
        }
      );
    }
  }

  ngOnDestroy() {
    this.codeReader.reset();
  }
}
