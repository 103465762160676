import {Injectable} from '@angular/core';
import {
  CurrencyService,
  CurrencyStatePersistenceService,
  SiteContextConfig,
  StatePersistenceService
} from '@spartacus/core';
import {GlobalLogin} from '../../model/misc.model';


@Injectable({ providedIn: 'root' })
export class SsabCurrencyStatePersistenceService extends CurrencyStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected currencyService: CurrencyService,
    protected config: SiteContextConfig
  ) {
    super(statePersistenceService, currencyService, config);
  }

  protected onRead(valueFromStorage: string): void {
    if (!this.currencyService.isInitialized() && valueFromStorage) {
      this.currencyService.setActive(valueFromStorage);
    }

    if(!valueFromStorage){
      this.currencyService.setActive(GlobalLogin.GlobalCurrency);
    }

    if (!this.initialized$.closed) {
      this.initialized$.next();
      this.initialized$.complete();
    }
  }
}
