import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AsmAuthStorageService, CsAgentAuthService} from '@spartacus/asm/root';
import {OAuthLibWrapperService, UserIdService,} from '@spartacus/core';
import {SsabAuthService} from '../service/user-auth/ssab-auth.service';
import {SsabAsmConnector} from './ssab-asm.connector';
import {UserProfileFacade} from '@spartacus/user/profile/root';
import {UserAccountFacade} from "@spartacus/user/account/root";

@Injectable({
  providedIn: 'root',
})
export class SsabCsAgentAuthService extends CsAgentAuthService {

  constructor(protected authService: SsabAuthService,
              protected authStorageService: AsmAuthStorageService,
              protected userIdService: UserIdService,
              protected oAuthLibWrapperService: OAuthLibWrapperService,
              protected store: Store,
              protected userProfileFacade: UserProfileFacade,
              protected ssabAsmConnector: SsabAsmConnector,
              protected userAccountFacade: UserAccountFacade) {

    super(authService, authStorageService, userIdService, oAuthLibWrapperService, store, userProfileFacade, userAccountFacade);
  }

  public authorizeCustomerSupportAgentAzure(userId: string, password: string): void {
    super.authorizeCustomerSupportAgent(userId, password)
      .then(() => {
        this.authService.navigateToHomePageOrLoginPage();
      })
      .catch(err => {
        console.error(err);
      });
  }

  public authorizeCustomerSupportAgent(userId: string, password: string): Promise<void> {
    return super.authorizeCustomerSupportAgent('__asm__' + userId, password);
  }

  public startCustomerEmulationSession(customerId: string): void {
    this.ssabAsmConnector.refreshEmulatedUser(customerId).subscribe();
    super.startCustomerEmulationSession(customerId);
    this.authService.asmRedirectUponEmulation();
  }
}
