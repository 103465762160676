<ng-container *ngIf="items$ | async as items">
  <div class="table-responsive p-2" *ngIf="items.length > 0; else emptyMiniCart">
    <h3 class="text-primary">{{ 'miniCart.title' | cxTranslate}}
      <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="close(true)"></span>
    </h3>
    <table class="table">
      <tbody [ngClass]="{'no-available-prod': hasCartNoAvailableProducts(items) }">
      <tr *ngFor="let item of items" class="d-flex">
        <td class="col-2 pl-0">
          <a
            [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
            cxModal="dismiss"
            tabindex="-1"
            class="d-inline"
          >
            <cx-media
              [container]="item.product.images?.PRIMARY"
              format="cartIcon"
            ></cx-media>
          </a>
        </td>
        <td class="col-sm-6 col-8 pl-0">
          <a
            [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
            cxModal="dismiss"
            tabindex="-1"
          >
            <span class="d-inline-block text-bold">{{item.product.name}} </span>
          </a>
          <span>{{item.quantity}} <span [innerHTML]="item.unit?.name"></span>
            </span>
          <span class="float-right d-block d-sm-none text-bold">
              <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
                {{ item.totalPrice?.formattedValue }}
              </ng-container>
            </span>
        </td>
        <td class="col-sm-3 d-none d-sm-block pl-2 text-bold vertical-align-item w-100">
          <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
            {{ item.totalPrice?.formattedValue }}
          </ng-container>
        </td>
        <td class="col-1 vertical-align-item-end w-100">
          <cx-icon
            aria-label="search"
            class="ssab-icon icon-system-trash icon-cursor"
            (click)="removeItem(item)"
            tabindex="0"
          ></cx-icon>
        </td>
      </tr>
      </tbody>
    </table>
    <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
      <div class="text-md-right text-center pr-md-5 pr-0 pb-4 pt-2">
        <span class="pr-3 text-sm text-uppercase text-bold ">{{ 'miniCart.total' | cxTranslate  }}</span>
        <ng-container *ngIf="total$ | async as total">
          <span class="text-paragraph-lg text-bold">{{total }}</span>
        </ng-container>
      </div>
    </ng-container>
    <div class="pt-4 d-flex justify-content-center text-danger text-center pb-4" *ngIf="hasCartNoAvailableProducts(items)">
      <span [innerHtml]="'miniCart.cartNoAvailableProducts' | cxTranslate"></span>
    </div>
    <div class=" text-md-right text-center pr-md-5 pr-0 w-100 pb-3">
      <button [routerLink]="{ cxRoute: 'cart' } | cxUrl" class="btn btn-default-height btn-primary">
        {{ 'miniCart.proceedToCheckout' | cxTranslate | uppercase}}
      </button>
    </div>

  </div>
</ng-container>

<ng-template #emptyMiniCart>
  <div class="p-2">
    <h3 class="text-center">{{ 'miniCart.empty.title' | cxTranslate}}</h3>
    <div class="pt-3 text-center">
      <div class="pb-4" [innerHTML]="'miniCart.empty.content' | cxTranslate"></div>
      <button [routerLink]="{ cxRoute: 'search', params: { query: '' } } | cxUrl" class="btn btn-primary btn-default-height">
        {{ 'miniCart.empty.search.products' | cxTranslate | uppercase}}
      </button>
    </div>
  </div>
</ng-template>
