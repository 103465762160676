import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProductUnitConversion, SsabProduct} from '../../../model/product.model';

@Component({
  selector: 'ssab-cx-product-attributes',
  templateUrl: './ssab-product-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductAttributesComponent {

  @Input() product: SsabProduct;

  formatDouble(value: string): string {
    if (value.includes(',')) {
      const doubleFormat = value.split(',');
      if (doubleFormat.length === 2 && doubleFormat[1].length >= 3 && doubleFormat[1].endsWith('0')) {
        doubleFormat[1] = doubleFormat[1].slice(0, -1);
        return doubleFormat[0] + ',' + doubleFormat[1];
      }
      return value;
    }
    return value;
  }

  pickCorrectBundle(puc: ProductUnitConversion[]): ProductUnitConversion {
    const ret = puc.find(p => p.unit.code === p.fromUnit.code);
    return ret?.bundleSize !== undefined ? ret : puc.find(p => p.bundleSize !== undefined);
  }
}
