import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import {HostListener} from "@angular/core";

@Component({
  selector: 'ssab-floating-scrollbar',
  templateUrl: './ssab-floating-scrollbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SsabFloatingScrollbarComponent implements AfterViewInit {
  boundingRect: any;
  shouldResize: boolean;

  constructor() {
  }

  @ViewChild('target') tar: ElementRef

  ngAfterViewInit(): void {
    this.boundingRect = this.tar.nativeElement.getBoundingClientRect();
    //We only want to resize the element if a scrollbar is present
    this.shouldResize = this.tar.nativeElement.children[0].scrollWidth > this.tar.nativeElement.children[0].clientWidth;

    if (this.shouldResize) {
      this.resize();
    }
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  resize(): void {
    if (this.shouldResize) {
      const targetHeight: number = Math.round((window.innerHeight - this.boundingRect.top) + window.pageYOffset);
      const isInBottom = targetHeight >= this.tar.nativeElement.clientHeight;
      this.tar.nativeElement.children[0].setAttribute('style', 'max-height: ' + targetHeight + 'px; overflow-y: hidden;');
      // hide pagination if we haven't scrolled to bottom
      this.tar.nativeElement.parentElement?.parentElement?.parentElement?.parentElement?.children[1]?.setAttribute('display', isInBottom ? 'block' : 'none');
    }
  }
}
