import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {CarouselModule, GenericLinkModule, IconModule, MediaModule, PageComponentModule} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import {SsabInformationLinkColumnComponent} from './information-link/column/ssab-information-link-column.component';
import {SsabInformationLinkComponent} from './information-link/ssab-information-link.component';
import {SsabHeroBannerComponent} from './hero-banner/ssab-hero-banner.component';
import {SsabHeroComponent} from './hero/ssab-hero.component';
import {SsabBannerGridComponent} from './banner-grid/ssab-banner-grid.component';
import {SsabBannerGridTileComponent} from './banner-grid/tile/ssab-banner-grid-tile.component';
import {SsabCarouselContainerComponent} from './carousel-container/ssab-carousel-container.component';
import {SsabCarouselBannerComponent} from './carousel-banner/ssab-carousel-banner-column.component';
import {SsabCarouselComponent} from './carousel/ssab-carousel.component';
import {SsabProductCarouselComponent} from './product-carousel/ssab-product-carousel.component';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        GenericLinkModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SsabInformationLinkComponent: {
                    component: SsabInformationLinkComponent
                },
                SsabInformationLinkColumn: {
                    component: SsabInformationLinkColumnComponent
                },
                SsabHeroBannerComponent: {
                    component: SsabHeroBannerComponent
                },
                SsabHeroComponent: {
                    component: SsabHeroComponent
                },
                SsabBannerGridComponent: {
                    component: SsabBannerGridComponent
                },
                SsabBannerGridTileComponent: {
                    component: SsabBannerGridTileComponent
                },
                SsabCarouselComponent: {
                    component: SsabCarouselContainerComponent
                },
                SsabCarouselBannerComponent: {
                    component: SsabCarouselBannerComponent
                },
                CarouselComponent: {
                    component: SsabCarouselComponent
                },
                ProductCarouselComponent: {
                    component: SsabProductCarouselComponent,
                },
            },
        } as CmsConfig),
        PageComponentModule,
        CarouselModule,
        IconModule,
        I18nModule,
        UrlModule,
        NgbPopover,
    ],
    declarations: [SsabInformationLinkComponent,
        SsabInformationLinkColumnComponent,
        SsabHeroBannerComponent,
        SsabHeroComponent,
        SsabBannerGridComponent,
        SsabBannerGridTileComponent,
        SsabCarouselComponent,
        SsabCarouselBannerComponent,
        SsabCarouselContainerComponent,
        SsabProductCarouselComponent],
    exports: [SsabInformationLinkComponent,
        SsabInformationLinkColumnComponent,
        SsabHeroBannerComponent,
        SsabHeroComponent,
        SsabBannerGridComponent,
        SsabBannerGridTileComponent,
        SsabCarouselComponent,
        SsabCarouselBannerComponent,
        SsabCarouselContainerComponent,
        SsabProductCarouselComponent]
})
export class SsabBannersModule { }
